.sponsorMenu {
    &.ant-menu {
        padding: 24px 0px;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        padding: 10px 8px !important;
        margin: 0px 4px;
    }

    .ant-menu-submenu-title {
        display: flex;
        align-items: center;
    }

    .ant-menu-submenu-title i.ant-menu-submenu-arrow {
        right: 24px;
    }

    .ant-menu-item img.ant-menu-item-icon,
    .ant-menu-submenu img {
        margin-right: 8px;
    }

    .ant-menu-item > *:only-child,
    .ant-menu-submenu > *:only-child {
        padding-left: 24px;
    }

    p,
    span {
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: -0.03em;
        text-align: left;
    }

    .ant-menu-submenu-selected {
        color: initial;
    }

    .ant-menu-item-selected {
        a,
        svg,
        a:hover {
            color: white;
        }
        background-color: #377bf7 !important;
        border-radius: 4px;

        &.ant-menu-item-active a:hover {
            color: white;
        }
    }

    a:hover {
        text-decoration: none;
    }
}
