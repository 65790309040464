.venue-options,
.github-options {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 64px;
    position: relative;
    h3 {
        margin-bottom: 20px;
    }
    .venue-option-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 600px;
        max-width: 100%;

        .venue-option-list-item {
            display: flex;
            padding: 24px 32px;
            flex-direction: column;
            border: 1px solid #e0e7ff;
            margin-bottom: 10px;
            width: 100%;
            .content {
                display: flex;
                padding: 10px 0;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                .left-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .icon {
                        margin-right: 5px;
                    }
                    .title {
                        display: flex;
                        flex-direction: column;
                    }
                }
                .gh-cta-actions {
                    max-width: 130px;
                    button {
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .item-button {
                    text-align: center;
                    border-radius: 4px;
                    max-width: 130px;
                    min-width: 130px;
                    word-break: break-word;
                    flex-grow: 1;
                    height: auto;
                    font-size: 13px;
                    cursor: pointer;
                    span {
                        word-break: break-word;
                        width: 100%;
                        display: block;
                        box-sizing: border-box;
                        display: flex;
                        flex-wrap: wrap;
                        white-space: normal;
                        text-align: center;
                        justify-content: center;
                    }
                }
            }
            .content-bottom {
                border-top: 1px solid #e0e7ff;
            }
            .list-of-gh-account {
                padding: 10px 0;
                p {
                    margin: 0;
                }

                .head {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    font-size: 12px;
                    .ant-avatar,
                    .ant-avatar.ant-avatar-icon {
                        margin: 0 5px;
                        flex-shrink: 0;
                    }
                    .name {
                        font-weight: bold;
                        flex: 1 1;
                    }
                    .configure-cta {
                        color: #236df2;
                        margin: 0 5px;
                        cursor: pointer;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .github-selection {
        border: 1px solid #e0e7ff;
        padding: 24px 32px;
        min-width: 600px;
        max-width: 100%;
        position: relative;
        .Loader {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(000, 000, 000, 0.4);
            z-index: 1;
        }
        .github-selection-header {
            border-bottom: 1px solid #e0e7ff;
            padding: 10px 0;
            margin-bottom: 10px;
            .head {
                display: flex;
                align-items: center;
                margin: 0;
                font-size: 12px;
                .ant-avatar,
                .ant-avatar.ant-avatar-icon {
                    margin: 0 5px;
                }
                .name {
                    font-weight: bold;
                    flex: 1 1;
                }
            }
        }
        .github-selection-body {
            .input-group {
                margin-bottom: 20px;

                p {
                    margin-bottom: 5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.github-options-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        min-width: 80px;
        border-radius: 4px;
        margin: 0 5px;
    }
}

.add-venue-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .uploader {
        .upload-div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 400px;
            height: 256px;
            border: 2px dashed #dadeeb;
            box-sizing: border-box;
            border-radius: 4px;
            .file-uploader {
                .file-uploader2__controls {
                    li {
                        margin: 0;
                        .upload-control {
                            padding: 7px 16px;
                            background: #e2e9f3;
                            border-radius: 4px;
                            color: #0b1c2c;
                        }
                    }
                }
            }
            span {
                font-family: 'Open Sans', sans-serif !important;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                letter-spacing: -0.03em;
                color: #313a44;
            }
        }
        .recommendation {
            font-family: 'Open Sans', sans-serif !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            letter-spacing: -0.03em;
            color: #313a44;
        }
    }
    .buttons {
        margin-top: 80px;
        .btn {
            &.btn-primary {
                font-family: 'Open Sans', sans-serif !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 13px !important;
                line-height: 18px !important;
                letter-spacing: -0.02em;
                color: #ffffff;
                border: 0;
                padding: 5px 10px !important;
                border-radius: 4px !important;
                vertical-align: middle;
                width: 102px;
                height: 32px;
                margin-left: 5px;
            }
            &.btn-default {
                font-family: 'Open Sans', sans-serif !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 13px !important;
                line-height: 18px !important;
                letter-spacing: -0.02em;
                color: #0b1c2c;
                background: #e2e9f3;
                border: 0;
                padding: 5px 10px !important;
                border-radius: 4px !important;
                vertical-align: middle;
                width: 102px;
                height: 32px;
                margin-left: 5px;
            }
        }
    }
}

.choose-git-hub-repo {
    .ant-modal-content {
        .ant-modal-body {
            margin: 0 auto;
        }
    }
}