@import "../../../utils//styles/mixins";

.general-preloader {
  ul {
    @include list-reset;

    margin-bottom: 30px;
  }

  li {
    width: 100%;
    height: 20px;
    background: #eee;
    margin-bottom: 10px;
    border-radius: 5px;

    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    backface-visibility: hidden;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 100% 0;
    }
  }
}
