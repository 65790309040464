@import "../../../utils/styles/variables";
@import "../../../utils/styles/mixins";

.showcase-editor {
  padding: $general-padding;

  &__logo {
    &-wrap {
      margin: 0 auto 20px;
      width: 148px;
    }

    &-note {
      text-align: center;
    }
  }
}

.call-to-action {
  &__preview {
    padding: 40px;
    background: #f1f5f9;
    border-radius: 7px;
    margin-bottom: 20px;
    text-align: center;

    i {
      margin-right: 8px;
    }

    img {
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }

    button {
      border: 1px solid #1640e8;
      background: #1640e8;
      border-radius: 30px;
      padding: 10px 30px;
      pointer-events: none;
      line-height: 1;
    }
  }

  &__icon-list {
    @include list-reset;
    display: flex;

    li {
      margin-right: 5px;
      border: 1px solid #444e4f;
      border: 1px solid #d9e1e8;
      cursor: pointer;
      border-radius: 3px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__icon-active {
    background-color: #d9e1e8;
  }
}

.icon-selector {
  @include list-reset;

  height: 180px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 30px;
  border: 1px solid #d9e1e8;
  padding: 7px;
  border-radius: 5px;

  li {
    display: inline-block;
    padding: 5px;
    line-height: 1;
    border: 1px solid #d9e1e8;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    margin: 3px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #d9e1e8;

      .fas {
        color: #333;
      }
    }
  }

  &__item {
    &--active {
      background-color: #007bff;
      border: 1px solid #007bff;

      .fas {
        color: #fff;
      }
    }
  }
}
