.card-side-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    .your-plan-text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.03em;
        color: #848484;
        margin-bottom: 5px;
    }
    .plan-name {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #000000;
    }
    .upgrade {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #0088eb;
        margin-right: 12px;
        cursor: pointer;
    }
}

.usage-container {
    background: #ffffff;
    border: 1px solid #dce4fd;
    border-radius: 8px !important;
    margin: 16px 0px;
    width: '100%' !important;
}

.loader-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.descriptionBox {
    padding: 16px;
    background: #f3f5fa;
    border-radius: 8px;
    margin-bottom: 24px;
    display: inline-flex;
    span {
        display: inline-block;
        max-width: 85%;
    }
}

.upgradeBtn {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #feffff;
    background: #008060;
    border-radius: 4px;
    padding: 7px 16px;
    border: none;
    margin: 20px 24px;
    cursor: pointer;
}

.attendee-usage-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
}

.project-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #575656;
}

.project-redirect-image {
    width: 28px;
    height: 23px;
    cursor: pointer;
    margin-left: 5px;
}

.project-redirect-image:hover {
    background: #f3f5fa;
}

.usage-duration-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    letter-spacing: -0.02em;
    color: #000000;
}

.attendee-count {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #575656;
}
.record-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #575656;
}

.border-bottom {
    border-bottom: 1px solid #e0e8f4;
}

.project-breakdown-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.usage-card {
    padding: 16px;
    min-height: 100px;
    background: #f3f5fa;
    border-radius: 4px;
}

.usage-card-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: #000000;
}

.current-usage {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #000000;
}

.usage-numbers {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #505050;
}

.limit-exceeded {
    color: #f24343 !important;
}
