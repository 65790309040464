@import '../../utils/styles/mixins';

.file-uploader {
    position: relative;

    &__drag-area {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #f3f5fa;
        font-weight: 500;
        font-size: 13px;
        color: #8c91a2;
        cursor: pointer;

        &--xlarge {
            width: 100%;
            padding: 30px 50px;
            background: #fff;
            border: 2px dashed #dadeeb;
            border-radius: 4px;
            position: relative;
            min-width: 400px;
            min-height: 200px;

            h3 {
                color: #333;
                font-size: 1.1rem !important;
            }

            > *,
            p:last-child {
                margin-bottom: 0;
                font-size: 0.8rem;
            }
        }

        &--large {
            width: 100%;
            padding: 2px;
            border: 2px dashed #dadeeb;
            border-radius: 4px;
            height: 70px;
            position: relative;

            > * {
                margin-bottom: 0;
            }
        }

        &--small {
            width: 70px;
            padding: 2px;
            border: 1px solid #dadeeb;
            border-radius: 4px;
            height: 70px;
            position: relative;

            p {
                @include centerer;
                margin-bottom: 0;
                width: 100px;
            }
        }
    }

    &__text {
        @include centerer;
        color: #c0cddc;
        margin-bottom: 0;
        width: 100px;
        font-size: 0.8rem;
        line-height: 1rem;
        padding: 0 15px;
    }

    &__progress {
        width: 70%;
        margin: 0 auto;
        height: 5px;
        border-radius: 5px;
        background: #dadeeb;

        &-bar {
            background: #34ae73;
            height: 5px;
            border-radius: 5px;
            transition: 500ms width ease;
        }
    }

    &__file-list-pending {
        ul {
            @include list-reset;
        }

        li {
            margin-bottom: 10px;
            font-size: 0.9em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #8b99a8;
        }

        &--large {
            margin-top: 20px;
        }

        &--xlarge {
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            border: 2px dashed #dadeeb;
            border-radius: 4px;

            ul {
                li {
                    display: block;
                    width: 100%;
                    margin-bottom: 0;
                    padding: 0 10px;
                    text-align: center;
                }
            }
        }
    }

    &__file-list {
        ul {
            @include list-reset;
        }

        li {
            margin-bottom: 10px;
            font-size: 0.9em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #8b99a8;
        }

        &--large {
            margin-top: 20px;
        }

        &--xlarge {
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            border: 2px dashed #dadeeb;
            border-radius: 4px;

            ul {
                li {
                    display: block;
                    width: 100%;
                    margin-bottom: 0;
                    padding: 0 10px;
                    text-align: center;
                }
            }
        }
    }

    &__controls {
        @include list-reset;
        display: flex;
        font-size: 13px;
        color: #007bff !important;
        white-space: nowrap !important;
        font-weight: 500 !important;
        cursor: pointer;
        margin-top: 10px;
        text-align: left;

        label {
            font-weight: 500 !important;
            font-size: 13px;
            cursor: pointer;
        }

        & li {
            margin-right: 7.5px;
            margin-left: 7.5px;
        }

        &.__large {
            margin-top: 10px;
        }
        &.__small {
        }
    }

    &__file-thumbnail {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 5px;

        &-action {
            cursor: pointer;
            color: #007bff;
        }
    }

    &__image-preview {
        width: 55px;
        height: 55px;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__reject {
        color: #ff5252 !important;
    }
}

.file-preview {
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    .preview-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        .name {
            font-weight: bold !important;
        }
    }
}

.file-uploader2__controls {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    li {
        .upload-control {
            display: flex;
            align-items: center;
            margin: 0 2px;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
.file-uploader__drag-area--small {
    margin: 0 auto 10px;
}
