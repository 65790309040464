.container2 {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 100%;

    @media screen and (max-width: 767px) {
        padding: 2rem;
    }

    .wizard-layout {
        height: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;
        .wizard-layout__navigation {
            position: absolute;
        }
    }

    .upload-people-file:first-child {
        width: 500px;
        padding: 1rem;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}

.z1 {
    z-index: 1;
}
