@import "../../../../utils/styles/mixins";

.showcase-dashboard {
  &__list {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 100px;
      position: relative;
      font-size: 0.9em;
      display: inline-block;
      margin-right: 40px;
    }
  }

  &__template {
    &:hover {
      > .showcase-dashboard__template-delete {
        display: block;
      }
    }

    &-input {
      width: 100%;
      padding: 7px;
    }

    &-name {
      color: #8798ad;
      line-height: 1.2em;
      text-align: center;
      height: 40px;
    }

    &-box {
      width: 100%;
      height: 100px;
      border-radius: 20px;
      margin-bottom: 8px;
      cursor: pointer;

      &--add-tile {
        border: 2px dashed #dee0e3;
        background: #fff url("/assets/icon_plus.svg") center center no-repeat;

        &:hover {
          background-color: #f4f4f4;
          border: 2px dashed #cdd2d8;
        }
      }

      &--tile {
        background: url("/assets/icon_tile_big.svg") no-repeat center center
          #7c3fe4;

        &:hover {
          background-color: #965ff3;
        }
      }
    }

    &-delete {
      display: none;
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
      background: url("/assets/icon_close.svg") no-repeat center center;
      background-size: 10px 10px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 0 3px #666;

      &:hover {
        background-color: #eee;
      }
    }
  }

  &__loading {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;

    > li {
      animation-duration: 10s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;

      width: 100px;
      height: 100px;
      position: relative;
      font-size: 0.9em;
      display: inline-block;
      margin-right: 40px;
      background: #e0e3ec;
      border-radius: 20px;
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
}
