.Container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1;
    height: 100%;
}
.Container::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
.Container::-webkit-scrollbar-thumb {
    background: none;
}

.Contents {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
}
.Contents::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
.Contents::-webkit-scrollbar-thumb {
    background: none;
}

.Header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #e3e8f7;
}
.Title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-transform: capitalize;
}
.Title h2,
.Title h2 p,
.Title h2 * {
    margin: 0;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 29px !important;
    letter-spacing: -0.04em !important;
    color: #000000;
    padding: 0 !important;
    border-bottom: 0px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 202px;
    text-align: left !important;
}
.Title h2 {
    display: flex;
    max-height: 30px;
}
.Title span {
    margin-top: -5px;
    cursor: pointer;
    margin-right: 15px !important;
}
.Title span svg {
    transform: rotate(-90deg);
}
.MoreDots {
    cursor: pointer;
}
