@import "../../../utils/styles/mixins";

.table-preloader {
  td{
    &:first-child{
      width: 340px;
    }
    div{
      width: 100%;
      height: 20px;
      background: #eee;
      border-radius: 4px;

      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      -webkit-animation: placeHolderShimmer 3s infinite;
      background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
      backface-visibility: hidden;
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  @-webkit-keyframes placeHolderShimmer
  {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
}
