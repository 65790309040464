@import "../utils/styles/mixins";

.error-boundary {
  &__content {
    @include centerer;
    width: 100%;
  }

  p {
    font-size: 1rem;
  }

  details {
    white-space: pre-wrap;
    cursor: pointer;
    font-size: 0.8em;
  }

  span {
    text-decoration: underline;
    color: rgb(43, 40, 228);
    cursor: pointer;
  }
}
