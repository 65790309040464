.query-builder-container {
    // border: 1px solid rgba(46, 91, 255, 0.15);
    // padding: 15px;
    // border-radius: 5px;
    width: 100%;
    .query-builder {
      overflow: hidden;

      * {
        box-sizing: border-box;

        &::before,
        &::after {
          box-sizing: border-box;
        }
      }

      &.qb-dragging {
        // cursor: -webkit-grabbing !important;
        // cursor: grabbing !important;

        button {
          pointer-events: none;
        }
      }
    }

    // .qb-drag-handler {
    //   cursor: -webkit-grabbing;
    //   cursor: grabbing;
    //   margin-left: 5px;
    // }

    .group--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group--conjunctions {
        display: flex;
        align-items: center;

        input {
          display: none !important;

          &[type="radio"]:checked+label {
            background-color: #236DF2;
            color: #fff;
          }

          &[type="checkbox"]:checked+label {
            background-color: #236DF2;
            color: #fff;
          }

          &:nth-child(1) {
            &+label {
              border-radius: 3px 0 0 3px;
            }
          }

          &:nth-child(5) {
            &+label {
              border-radius: 0 3px 3px 0;

            }
          }
        }



        label {

          padding-left: 0px !important;
          padding: 3px 10px !important;
          margin: 0;
          border: 1px solid #BFCADA;
          font-weight: normal;
          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          border-radius: 1px;
          line-height: initial;
          &::before,
          &::after {
            display: none;
          }
        }

        .group--drag-handler {
          margin-left: 10px;
        }

        &.hide--conj {
          opacity: 0.3;
        }
      }

      &,
      .group--footer {
        margin: 10px 2px 0px 2px
      }

      .group--actions {
          display: flex;
          flex-direction: column;
        >button {

          &:nth-child(1) {
            padding: 3px 10px !important;
            border: 1px solid #BFCADA;
            background-color: #fff;
            font-weight: 500;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            border-radius: 3px 0 0 3px;
            color: #000;

            &::before {
              font-family: "Font Awesome 5 Free";
              content: "\f067";
              font-weight: 900;
              margin-right: 5px;
            }
          }

          &:nth-child(2) {
            padding: 3px 10px !important;
            border: 1px solid #BFCADA;
            background-color: #EBF0F4;
            font-weight: 500;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            border-radius: 0 3px 3px 0;
            color: #000;

            &::before {
              font-family: "Font Awesome 5 Free";
              content: "\f65e";
              font-weight: 900;
              margin-right: 5px;
            }
          }

          &:nth-child(3) {
            border: none;
            background-color: #D4DAE2;
            font-weight: 500;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            width: 23px;
            height: 23px;
            border-radius: 25px;
            color: #000;
            margin-left: 10px;
          }
        }
      }
    }

    .group-or-rule-container {
      margin-top: 10px;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 0px !important;
      }

      &:last-child {
        margin-bottom: 0px !important;
      }

      .group--children {
        margin: 0px 0 5px;
        position: relative;
        padding-left: 0;


        &.hide--conjs>.group-or-rule-container:first-child>.group-or-rule,
        &.rule_group--children>.group-or-rule-container:first-child>.group-or-rule {
          &::before {
            display: none;
          }

          &::after {
            border-radius: 4px 0 0 0;
            border-width: 2px 0 0 2px;
          }
        }


        >.group-or-rule-container {
          &:first-child>.group-or-rule::before {
            top: -12px;
            height: calc(50% + 14px);
          }

          &:last-child>.group-or-rule {
            &::before {
              border-radius: 0 0 0 4px;
            }

            &::after {
              display: none;
            }
          }
        }

        &.hide--line>.group-or-rule-container>.group-or-rule {

          &::before,
          &::after {
            border-color: rgba(128, 128, 128, 0.1);
          }
        }

      }


      .rule {
        display: flex;
        flex: 1;
        justify-content: space-between;
        background-color: #EBF0F4;
        margin: 5px 0;
        padding: 6px;
        // width: 74%;
        margin-left: 24px;
        .rule--drag-handler {
          display: flex;
          align-items: center;
          align-items: flex-start;
          margin-right: 3px;
        }

        .rule--body--wrapper {
          flex: 1;

          .rule--body {
            display: flex;
            flex-direction: column;

            &>div {
              margin-right: 0px;
              margin-bottom: 5px;
              width: 159px;
            }

            select,
            input {
              display: block;
              width: 100%;
              height: calc(1.5em + .75rem + 2px);
              padding: .375rem .75rem;
              font-size: 12px !important;
              font-weight: 400;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: .25rem;
              transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            }

          }
        }

        .rule--header {
          margin-left: auto;
          display: flex;
          align-items: flex-start;

          button {
            border: none;
            background-color: #D4DAE2;
            font-weight: 500;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            width: 23px;
            height: 23px;
            border-radius: 25px;
            color: #000;
            margin: 0px 5px;
          }
        }
      }

      &>.group-or-rule {
        border-radius: 5px;
        position: relative;
        border: 0;
        padding-right: 0px;

        &:before {
          top: -4px;
          border-width: 0 0 2px 2px;
        }

        &::after {
          top: 50%;
          border-width: 0 0 0 2px;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          left: -14px;
          width: 14px;
          height: calc(50% + 8px);
          border-color: #D4DAE2;
          border-style: solid;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
      }
    }

  }

  .qb-draggable,
  .qb-drag-handler {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    svg {
      display: none
    }

    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f58e";
      font-weight: 900;
      margin-right: 5px;
      color: #878DA1;
    }
  }

  .qb-lite {
    .group--children {
        .group--header {
            .group--actions,
            .group--conjunctions {
                display: none;
            }
        }
    }
    .group--actions {
        // margin-right: 25px;
    }
    .group--drag-handler,
    .group--actions {
      transition: opacity 0.2s;
    }

    .group--header {
      &:hover .group--header {

        .group--drag-handler,
        .group--actions {
          opacity: 0;
        }
      }

      &:not(:hover) {

        .group--drag-handler,
        .group--actions {
          opacity: 1;
        }
      }
    }

    .rule_group:not(:hover) {

      .group--drag-handler,
      .group--actions {
        opacity: 1;
      }
    }

    .group--conjunctions .ant-btn:not(.ant-btn-primary) {
      transition: padding 0.2s;
    }

    .group--header {

      &:hover .group--header .group--conjunctions .ant-btn:not(.ant-btn-primary),
      &:not(:hover) .group--conjunctions .ant-btn:not(.ant-btn-primary) {
        width: 0;
        padding: 0;
        overflow: hidden;
        opacity: 1;
      }
    }

    .rule_group:not(:hover) .group--conjunctions .ant-btn:not(.ant-btn-primary) {
      width: 0;
      padding: 0;
      overflow: hidden;
      opacity: 1;
    }

    .widget--valuesrc,
    .rule--drag-handler,
    .rule--header {
      transition: opacity 0.2s;
    }

    .rule {
      &:hover .rule {

        .widget--valuesrc,
        .rule--drag-handler,
        .rule--header {
          opacity: 1;
        }
      }

      &:not(:hover) {

        .widget--valuesrc,
        .rule--drag-handler,
        .rule--header {
          opacity: 1;
        }
      }
    }

    &.qb-dragging {

      .widget--valuesrc,
      .rule--drag-handler,
      .rule--header,
      .group--drag-handler,
      .group--actions {
        opacity: 1 !important;
      }
    }
  }