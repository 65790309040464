.wizard-layout {
    height: 100vh;
    overflow-y: scroll;

    &__name {
        margin: 0;
    }

    &__navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 15px;
        display: flex;
        justify-content: center;

        &-wrap {
            display: flex;
            justify-content: space-between;
            width: 400px;

            button {
                font-size: 0.8rem;
            }
        }

        &-inner-wrap {
            width: 130px;
            display: flex;
            justify-content: flex-end;

            .btn {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__prev.btn {
        background-color: #eee;
    }
}
