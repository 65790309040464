.PopupBannerStripWrapper {
    @media screen and (max-width: 767px) {
        justify-content: unset !important;
        margin-bottom: 16px !important;
        overflow: auto;
    }
}

.notification_Banner_Seperator {
    background: linear-gradient(
        90deg,
        #5071c0 -10.01%,
        #a5a5d7 54.98%,
        #ac9fcb 101.54%
    );
}

.NotificationPopup-CTA-anchor .NotificationPopup-CTA,
NotificationPopup-CTA:hover button {
    background: var(
        --winter-2,
        linear-gradient(
            90deg,
            #5071c0 -10.01%,
            #a5a5d7 54.98%,
            #ac9fcb 101.54%
        ),
        #fff
    ) !important;
    border: none !important;
    padding: 13px 24px;
    color: #fff;
    font-size: 16px;
    min-width: 166px;
    max-height: 51px;
}

.NotificationPopup-button,
.NotificationPopup-button:hover {
    background: var(
        --fall2,
        linear-gradient(90deg, #cd1f25 2.66%, #f46322 64.16%, #ffa939 101.54%),
        #fff
    ) !important;
    border: none !important;
}
