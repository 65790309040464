.empty-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 168px;

  &__icon {
    margin-bottom: 20px;
    width: 150px;
  }

  .empty-component-rectangle {
    height: 90px;
    width: 71px;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 20px 0.2px rgba(178,199,238,0.4);
    margin-bottom: 16px;
  }

  .empty-component-title {
    color: #000;
    font-size: 30px !important;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 700 !important;
    text-align: center;
    line-height: 37px;
    letter-spacing: -0.85px;
  }

  .empty-component-sub {
    font-family: "Open Sans", sans-serif;
    font-weight: 400 !important;
    font-size: .9rem;
    color: #313A44;
    line-height: 1.2rem;
    letter-spacing: -0.51px;
    text-align: center;
    width: 365px;
    margin-bottom: 30px;
  }

  button {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.58px;
    padding: 10px;
    padding-left: 36px;
    padding-right: 36px;
  }
}
