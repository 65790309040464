.user-account-status {
    border: 1px solid #d5ddf1;
    padding: 20px;
    background: #fff;
    border-radius: 5px;

    @media screen and (max-width:640px) {
        border: none;
        background: none;
        border-radius: 0;
    }

    @media screen and (max-width: 575px) {
        text-align: center;
    }

    p {
        margin-bottom: 0;
        text-wrap: nowrap;

        @media screen and (max-width:640px) {
            text-wrap: wrap;
            font-size: 14px !important;
            text-align: justify;
        }
    }

    button,
    a {
        font-size: 0.8em;
        background: #798695 !important;
        color: #fff;

        &:hover {
            color: #fff;
        }

        @media screen and (max-width: 575px) {
            margin-bottom: 10px;
        }
    }

    &__bar {
        width: 100%;
        height: 30px;
        border-radius: 40px;
        background: #eee;
        position: relative;
        margin-bottom: 10px;

        @media screen and (max-width:640px) {
            height: 16px;
        }
    }

    &__progress {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, #377bf7 0%, #0098f78f 100%);
        height: 30px;
        border-radius: 40px;
        @media screen and (max-width:640px) {
            height: 16px;
        }
    }
}