.product-preview-modal {
    .ant-modal-content {
        .ant-modal-header {
            background-color: #eff3f6;
        }
    }
}

.product-header-text {
    font-size: 30px;
}

.product-company-name {
    font-size: 13px;
    font-weight: 600;
}

.badge-topics {
    color: #000000;
    background-color: #e1e9f1;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 14px;
}

.product-company-description {
    height: 107px;
    overflow: auto;
}

.product-carousel-thumbnail-item {
    border: 2px solid #fff;
    img {
        max-height: 85px;
    }
}

.product-carousel-item {
    background-color: #000;
}

.product-company-description-container {
    position: relative;

    label {
        position: absolute;
        top: 100%;
        font-size: 11px;
        color: #007ac3;
        font-weight: 300;
    }

    input {
        display: none;
    }

    label:after {
        content: 'Show more';
    }

    input:checked + label:after {
        content: 'Show less';
    }

    input:checked ~ p {
        height: 100%;
    }
}

.product-company-content {
    .product-company-content-header {
        color: #181818;
        font-weight: 600;
        font-size: 13px;
    }

    .product-company-content-desc {
        color: #687177;
        font-size: 13px;
    }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
    z-index: 90;
}

.ant-carousel .slick-initialized .slick-slide {
    position: relative;
    overflow: hidden;
    background-color: #000;
}

.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
    content: ' ';
}

.ant-carousel .slick-prev {
    left: 5px;
}

.ant-carousel .slick-next {
    right: 5px;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
    display: flex;
    align-items: center;
}
