.sideBar {
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #ffffff;
    box-shadow: 0 0 15px 0 rgba(178, 199, 238, 0.4);
    transition: 0.5s ease;
    overflow: auto;
}

.blanket {
    opacity: 0.3;
    background-color: #455564;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
}
