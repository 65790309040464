@import '../../utils/styles/mixins';
@import '../../utils/styles/variables';

.login {
    @include centerer;

    @media screen and (max-width: 420px) {
        max-width: 100%;
        width: 100%;
    }

    p {
        font-size: 1em;
    }

    &__logo {
        text-align: center;
        margin-bottom: 30px;
        max-width: 160px;
    }

    &__box {
        max-width: 400px;
    }

    input[type='text'] {
        padding: 30px 15px;
        font-size: 1.4em;
    }

    .checkbox-set {
        text-align: left;
        width: 100%;
    }

    .form-group {
        width: 100%;
    }

    button {
        font-size: 1.2em;
        padding: 15px;
        border: 1px solid #000000 !important;
        background: #000000 !important;
        box-shadow: none !important;

        span {
            line-height: 1;
        }

        img {
            width: 30px;
            height: 30px;
        }

        &:hover {
            border: 1px solid #000000;
            background: #000000;
        }
    }

    .checkbox-set {
        text-align: left;
        margin-bottom: 10px;

        label {
            display: inline-block;
            padding-right: 10px;
            white-space: nowrap;
        }

        input {
            vertical-align: middle;
        }

        label span {
            vertical-align: middle;
        }
    }

    .foot-note {
        padding-bottom: 40px;
        color: #797978;
        font-size: 12px;
        text-align: center;
        margin-bottom: 0;
        padding: 10px 10px 40px;

        a {
            text-decoration: none;
            color: dodgerblue;
        }
    }
}
