@import "../../../../utils/styles/mixins";
@import "../../../../utils/styles/variables";

.project {
  &__create{
    width: 400px;

    h1{
      font-weight: 700;
      font-size: 31px;
      line-height: 38px;
      letter-spacing: -0.34px;
    }

    & .create-body-inputs {
      position: relative;
      z-index: 100;

      // &.device-small {
      //   margin-bottom: 200px !important;
      // }

      h3 i{
        font-size: 12px;
        vertical-align: middle;
      }
    }

    & .create-footer {
      background: white;
    }
  }

  .file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    border-radius: 0.25rem !important;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #F3F5FA;
    border: 2px dashed #dee2e6 !important;
    color: #8C91A2;
    font-size: 0.8em;
    font-weight: 500;

    label {
      cursor: pointer;
      margin: 0 !important;
    }
  }

  .bg-options{
    font-size: 0.8em;
    font-weight: 500;

    & a {
      position: relative;

      &:hover{
        text-decoration: none;
      }

      &.active::after{
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: #000;
        content: "";
        bottom: -5px;
        left: 0;
      }
    }
  }

  .gallery {
    display:flex;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;

    & .imageloader {
      width: 90px;
      height: 53px;
      margin: 5px;
      cursor: pointer;

      &.active {
        border: 3px solid #2D76FA;
      }

      img{
        width: 90px;
        height: 53px;
        cursor: pointer;
      }
    }
  }
}
