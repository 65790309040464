@import '../../utils/styles/mixins';

.header {
    @include space-between;

    padding: 0 20px;
    width: 100%;
    border: 1px solid rgba(46, 91, 255, 0.15);
    z-index: 100;

    &.fixed {
        position: fixed;
        z-index: 999;
    }

    &--xpmanager {
        background: #fff;
        height: 58px !important;
        min-height: 58px !important;
        max-height: 58px !important;
    }

    &__back {
        margin-right: 10px;
    }

    &__custom {
        width: 80%;
        height: 100%;
    }

    &__profile_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 14px;
        background-color: #e4eaf7;
        color: #020202;
        float: right;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        img {
            width: auto;
            height: 100%;
            max-width: none;
        }

        span {
            color: #020202;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
        }
    }

    &__profile {
        width: auto;
        text-align: right;
        align-items: center;
        justify-content: center;
        font-size: 0.9em;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: flex-end;

        span.company-name {
            display: block;
            color: #000000;
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 18px;
            font-family: 'Montserrat Bold', sans-serif;
            font-weight: bold;
            margin-right: 10px;
        }

        @media screen and (max-width: 500px) {
            width: 50px;
        }

        span,
        a {
            display: block;
            color: #333;

            &:hover {
                text-decoration: none;
            }
        }

        &-name {
            font-weight: bold;
        }

        &-company {
            font-size: 0.7em;
        }

        &-user-set-mobile {
            display: none;

            @media screen and (max-width: 500px) {
                display: inline-block;
            }
        }
    }

    &__title {
        margin-right: 30px;
        font-weight: bold;

        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    &__inner {
        display: flex;
        height: 40px;
        align-items: center;

        >* {
            margin-bottom: 0;
        }
    }

    .Upgrade {
        display: flex;
        align-items: center;
        margin-right: 16px;
        flex-shrink: 0;

        p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 143.87%;
            margin: 0 5px 0 0;
            text-align: right;
            letter-spacing: -0.02em;
            color: #000000;
        }

        button,
        .BookADemo {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #feffff;
            background: #008060;
            border-radius: 4px;
            padding: 7px 16px;
            border: none;
        }

        .BookADemo {
            margin-left: 10px;
            background-color: #070b13;
            color: #fff;
            text-decoration: none;
        }
    }
}