.header-admin {
    &__title {
      font-weight: bold;
      text-transform: capitalize;
  
      @media screen and (max-width: 500px) {
        justify-content: center;
      }
  
      span {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
  
      img {
        margin-right: 7px;
  
        @media screen and (max-width: 500px) {
          margin-right: 0;
        }
      }
    }
  }
  