@import '../../../../utils/styles/mixins';

.header-main {
    padding: 0;
    display: flex;
    height: 100%;
    align-items: center;

    .switch-dashboard-cta {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        margin-left: 10px;
        background: #f3f5fb;
        border: 1px solid #dce2f4;
        box-sizing: border-box;
        border-radius: 60px;
        padding: 8px 12px;
        color: #000;
        text-decoration: none;
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
            display: block;
            width: 20px;
        }

        &:hover {
            text-decoration: none;
        }

        .span-link {
            color: #007bff;
        }
    }

    h1.active-company {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.03em;
        margin-right: 24px !important;
        text-transform: none;

        cursor: pointer;

        span {
            margin: 0 10px;
        }
    }

    .previous-arrow {
        width: 100%;
        display: block;
        transform: rotate(180deg);
    }

    .header-main__title-group {
        margin-right: 16px;
    }

    .header-main-inner {
        display: flex;

        a {
            display: block;
            width: 100%;
            height: 100%;

            img {
                display: block;
                width: 50%;
            }
        }
    }

    @media screen and (max-width: 500px) {
        padding: 5px;
    }

    .sponsor {
        width: 99px;
    }

    &__subtitle {
        font-size: 0.9em;
    }

    &__title {
        font-size: 19px !important;
        margin-bottom: 0;
        font-family: 'Montserrat ExtraBold', sans-serif;
        margin-left: -2px;
        transition: opacity 0.3s;

        &:hover {
            cursor: pointer;
            color: #125dff;
        }

        &-group {
            display: flex;
            margin-right: 20px;
            align-items: center;
        }

        &.hasdivider {
            border-left: 1px solid #d8d8d8;
            padding-left: 15px;
        }
    }

    &__sponsorTitle {
        font-size: 19px !important;
        margin-bottom: 0;
        font-family: 'Montserrat ExtraBold', sans-serif;
        margin-left: -2px;
        transition: opacity 0.3s;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 500px) {
            font-size: 24px !important;
            color: #101828;
            font-weight: 600;
            font-family: 'open sans', sans-serif;
        }
    }

    &__project-selector {
        align-self: flex-end;
    }

    &__nav {
        @include list-horizontal;
        align-self: flex-end;

        li {
            margin-right: 20px;
            font-weight: bold;

            a {
                color: #0070c9;

                &:hover {
                    text-decoration: none;
                    color: #333;
                }
            }
        }

        &--active {
            padding-bottom: 5px;
            border-bottom: 3px solid #0070c9;
        }
    }

    .label {
        margin-left: 5px;

        span.sponsor {
            font-family: 'Montserrat SemiBold', sans-serif;
            font-size: 10px;
            line-height: 0;
            color: #0083b8;
        }

        span.dashboard {
            font-family: 'Montserrat Bold', sans-serif;
            color: #0083b8;
            line-height: 8px;
        }
    }

    .project-title-h1 {
        display: flex;
        align-items: center;
        margin: 0;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.03em;
        color: #000000;
    }

    .header-menu-wrap {
        display: flex;
        margin: 0;
        align-items: center;

        .header-menu-list {
            list-style: none;
            margin: 0;
            padding: 0;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            letter-spacing: -0.03em;
            color: #b7c6d6;
            height: 100%;

            .header-menu-item {
                display: flex;
                align-items: center;
                margin-right: 32px;
                cursor: pointer;
                height: 100%;

                &:hover,
                &.active {
                    color: #000;
                    border-bottom: 1px solid #000;
                }

                &.disabled {
                    cursor: not-allowed;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}