@import '../../../../../utils/styles/mixins';

.user-list {
    width: 100%;
    border: 1px solid #d5ddf1;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    font-family: 'Open Sans';

    @media screen and (max-width: 768px) {
        border: none;
        padding: 0px;
    }

    &__filters {
        @include list-horizontal;

        margin-bottom: 30px;

        li {
            color: #181818;
            margin-right: 30px;
            font-weight: bold;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                color: #333;
            }

            &:last-child {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    &__filter {
        &--active {
            border-bottom: 3px solid #377bf7;
            color: black !important;
            opacity: 1 !important;
            font-weight: bold !important;
        }
    }

    &__table-wrap {
        overflow-x: auto;
    }

    &__table {
        width: 100%;

        thead {
            border-bottom: 1px solid #d5ddf1;

            th {
                padding: 7px 0;
                min-width: 200px;

                &:first-child {
                    padding-left: 20px;
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    background: #f7f7f7;
                }
            }
        }

        tr {
            border-bottom: 1px solid #d5ddf1;

            &:last-child {
                border: none;
            }
        }

        td {
            padding: 10px 0;
            position: relative;

            &:first-child {
                min-width: 130px;
                padding-left: 20px;
            }

            p {
                margin-bottom: 0;
            }

            .dotContainer {
                transform: rotate(90deg);
            }
        }
    }

    .extra-options {
        cursor: pointer;
        font-weight: bold;
        position: relative;
        color: #798696;
        bottom: 7px;
    }

    .dropdown-menu {
        a {
            &:active {
                background: none;
                color: #d5ddf1;
            }
        }

        hr {
            margin: 5px 0;
        }
    }

    .btn {
        &-secondary {
            background: #798695 !important;
            color: #fff;
            width: 160px;
            margin-bottom: 0;
        }
    }

    &__mobile {
        &-list {
            @include list-reset;

            li {
                padding: 15px 10px;
                border-top: 1px solid #d4daef;

                .btn {
                    &-secondary {
                        width: auto;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 640px) {
        font-family: 'Open Sans', sans-serif !important;
        box-shadow: 0px 1px 2px 0px #1018280f;
        border-radius: 12px;

        h2 {
            font-family: 'Open Sans', sans-serif !important;
            font-size: 16px !important;
        }

        p {
            font-size: 14px !important;
        }
    }
}
