.empty-notification-table {
    min-height: 450px;

    &>div {
        max-width: 390px;
    }

    h2 {
        font-size: 18px !important;
    }

    .empty-ellips {
        height: 116px;
        width: 116px;
        left: 144.5px;
        top: 0px;
        border-radius: 0px;
        background: #E5E9F8;
        border-radius: 100%;
    }
}

.channel-container {
    max-width: 720px !important;
}

@media screen and (max-width:640px) {
    label {
        font-family: 'Open Sans', sans-serif !important;
    }

    #mobileInput input {
        font-size: 16px !important;
        height: 44px;
    }
    #mobileInput label {
        font-size: 14px !important;
    }

}