.products {
    div[class='ant-tabs-nav-list'] {
        padding-left: 12px;
    }
    div[class*='sponsors-company-table'] ul[class*='ant-pagination'] {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
    }
}
.empty-notification-table {
    min-height: 450px;

    & > div {
        max-width: 390px;
    }

    h2 {
        font-size: 18px !important;
    }

    .empty-ellips {
        height: 116px;
        width: 116px;
        left: 144.5px;
        top: 0px;
        border-radius: 0px;
        background: #e5e9f8;
        border-radius: 100%;
    }
}

.sponsors-tabs {
    .ant-tabs-tab {
        padding: 16px;
    }
}
