@import "../../utils/styles/mixins";
@import "../../utils/styles/variables";

.alert {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 9px 13px 0 rgba(0,0,0,0.08);
  background: #fff;
  padding: 20px 20px 20px 40px !important;
  font-size: 1em;
  z-index: 1200;
  font-weight: bold;

  &__success {
    background: url("/assets/icon_check.svg") no-repeat 10px center #E2EFDA;
    color: #355330;
    border-bottom: 1px solid #9ed894;
  }

  &__error {
    background: url("/assets/icon_check.svg") no-repeat 10px center rgb(252, 215, 204);
    color: #e75353;
    border-bottom: 1px solid #f0abab;
  }

  &-enter {
    top: -50px;
  }

  &-enter-active {
    top: 0;
    transition: top 0.3s ease;
  }

  &-exit {
    top: 0;
    opacity: 1;
  }

  &-exit-active {
    top: -50px;
    opacity: 0;
    transition: all 0.3s ease;
  }
}
