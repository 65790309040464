.agendaTitle {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-family: Montserrat;
        font-size: 28px !important;
        font-weight: bold;
        letter-spacing: -0.49px;
        line-height: 34px;
    }
}

.agenda-search-container {
    .agenda-search {
        input {
            width: 144px;
            border-radius: 4px !important;
            transition: width 0.4s;
            @media screen and (max-width:640px) {
                border-radius: 8px !important;
                
            }
        }
        
        @media screen and (max-width:640px) {
            #mobileInput_mainSearch {
                font-size: 16px !important;
                height: 44px;
               padding:  0.375rem 2.3rem;
            }
            #mobileInput {
                font-size: 16px !important;
                height: 44px;
            }
        }

        .input-group-append {
            width: 0 !important;
            font-size: '16px !important';

            & span {
                background: transparent;
                border: none;
                position: relative;
                right: 34px;
                @media screen and (max-width:640px) {
                    right: 0;
                    .closeInputIcon{
                        position: relative;
                        right: 36px;
                    }
                }

                & i {
                    z-index: 20;
                    color: #a5a5a5;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    & a button {
        width: 162px !important;
    }
}

.sideTabs {
    color: #000000;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.28px;
    line-height: 19px;
    margin-right: 30px;
    margin-top: 30px;
    cursor: pointer;
}

.tabMarkerContainer {
    width: 180px;
    margin-top: 5px;
    height: 2px;
    text-align: center;
    transition: 0.5s ease;
}
.tabMarker {
    width: 40%;
    height: 2px;
    background-color: black;
    transition: 0.5s ease;
}
