.notification-box-container {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 99;
    width: 100%;
    max-width: 300px;
    transition: 0.5s ease-in-out;
    transform: translate3d(0, 0, 0);
    overflow-y: scroll;
    max-height: 75vh;

    img {
        height: 35px;
    }

    .sub-title {
        font-size: 11px;

    }

    .title {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        word-wrap: break-word;


    }

    .poll-radio-item span:nth-child(2) {
        max-width: 245px;
    }


}