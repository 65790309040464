@import '../../../utils/styles/mixins';

.block-creator {
    font-family: 'Open Sans', sans-serif;

    h2 {
        font-family: inherit;
    }

    &__body,
    &__preview {
        padding: 30px;
    }

    &__body {
        position: relative;
    }

    &__title {
        margin-bottom: 30px;
        display: flex;
    }

    &__no-preview {
        @include centerer;
        text-align: center;
        font-weight: bold;
        color: #c8d5da;
        font-size: 1em;
    }

    &__preview {
        background: #f1f5f9;
        height: 100%;
        border-radius: 0 20px 20px 0;

        @media screen and (max-width: 800px) {
            display: none;
        }

        &-thumbnail {
            @include centerer;

            margin-top: -20px;
            position: relative;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.13);

            img {
                border-radius: 20px;
                width: 100%;
                height: 100%;
                background: #f1f5f9;
            }

            p {
                padding: 10px 10px 15px 10px;
                font-weight: bold;
                line-height: 1.2;
                font-size: 0.9em;
            }

            &-img-wrap {
                background: #f1f5f9;
                border-radius: 20px;
            }

            &--small {
                width: 160px;

                .block-creator__preview-thumbnail-img-wrap {
                    height: 120px;
                }
            }

            &--large {
                width: 280px;

                .block-creator__preview-thumbnail-img-wrap {
                    height: 220px;
                }
            }
        }
    }

    &__preview-instruction {
        @include centerer-horizontal;
        bottom: 30px;
        font-size: 1em;
        text-align: center;
        font-weight: bold;
        color: #c8d5da;
    }
}
