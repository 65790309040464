.side-modal-header {
    .close-button {
        cursor: pointer;
        opacity: 0.5;
        position: relative;
        width: 10px;
        height: 15px;

        &:hover {
            opacity: 1;
            -webkit-transition: ease-in 0.2s;
            -moz-transition: ease-in 0.2s;
            -ms-transition: ease-in 0.2s;
            -o-transition: ease-in 0.2s;
            transition: ease-in 0.2s;
        }

        &::before,
        &::after {
            position: absolute;
            content: "  ";
            height: 15px;
            width: 2px;
            border-radius: 25%;
            background-color: #000;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}

.myxp-edit-prompt-modal{
    .modal-content{
        max-width: 100%!important;
        // min-width: 100%;
    }
   
}