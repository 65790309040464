// The MainMenu's nav ul
ul.app-mainmenu {
    margin-left: 16px;
    top: 20px;

    &.venue {
        &.nav-item.active {
            // For the active link marker
            &::before {
                content: '';
                position: absolute;
                width: 3px;
                height: 40px;
                background: #191919;
                left: -16px;
                display: block;
                margin-top: 2px;
                border-radius: 0;

                &.x {
                    margin-top: -1px !important;
                }
            }
        }

        // sub menu active state
        & .sidebar-venue:hover,
        &.active .sidebar-venue,
        & .sidebar-livepages:hover,
        &.active .sidebar-livepages,
        & .sidebar-menus:hover,
        &.active .sidebar-menus,
        & .sidebar-navigation:hover,
        &.active .sidebar-navigation,
        & .sidebar-authentication:hover,
        &.active .sidebar-authentication {
            color: #236df2;

            // &::before {
            //     background: #236df2 !important;
            // }
        }

        .nav-link {
            &.active {
                p.venue.sub {
                    color: #236df2;
                }
            }
        }
    }

    & .nav-item {
        position: relative;
        display: flex;
        align-items: center;
        height: 42px;
        margin-bottom: 24px;
        width: 0;

        &__sub {
            height: 20px;
            margin-left: 10px;
            margin-top: -6px !important;

            & a#sub {
                padding: 0;
            }
        }
    }

    & .nav-item.active {
        // For the active link marker
        &::before {
            content: '';
            position: absolute;
            width: 3px;
            height: 40px;
            background: #191919;
            left: -16px;
            display: block;
            margin-top: 2px;
            border-radius: 0;

            &.x {
                margin-top: -1px !important;
            }
        }

        a {
            color: #236df2;
        }
    }

    .sidebar-tags:hover,
    .active .sidebar-tags {
        &::before {
            background: url(/assets/products/xpmanager/iconsv2/tags-active.svg)
                50% 28% !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
        }
    }

    .sidebar-apps:hover,
    .active .sidebar-apps {
        &::before {
            background: url(/assets/products/xpmanager/iconsv2/apps-active.svg)
                50% 28% !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
        }
    }

    .sidebar-pushengagement:hover,
    .active .sidebar-pushengagement {
        &::before {
            background: url(/assets/products/xpmanager/iconsv2/notifications-active.svg)
                50% 28% !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
        }
    }

    .sidebar-products:hover,
    .active .sidebar-products {
        &::before {
            background: url(/assets/products/xpmanager/iconsv2/products-active.svg)
                50% 28% !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
        }
    }

    // All styles for <p></p>
    & .nav-item p {
        padding-left: 50px;
        margin-bottom: 0;

        &.sidebar-switch {
            padding-left: 45px;
        }

        // Styles for large font links
        &.main {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 13px !important;
            line-height: 16px;
            letter-spacing: -0.03em;
        }

        // Styles for small font links
        &.sub {
            // font-family: 'Open Sans', sans-serif;
            // font-weight: 700;
            // font-size: 12px !important;
            // letter-spacing: -0.3px;
            // line-height: 17px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.03em;
            color: #000000;
            margin-left: -15px;
            margin-bottom: 2px;
            padding-left: 48px;
            margin-top: 5px;
            width: 162px;
        }

        // Custom style for each nav link, inside <p></p>
        // cause we need to add icon
        // People Link
        &.sidebar-people {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/peoples-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-virtual {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/virtual-normal.svg)
                    no-repeat;
                border-radius: 4px;
            }
        }
        &.sidebar-badges {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/virtual-normal.svg)
                    no-repeat;
                border-radius: 4px;
            }
        }

        &.sidebar-agenda {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/agenda-normal.svg)
                    no-repeat;
                border-radius: 4px;
            }
        }

        &.sidebar-videos {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/iconsv2/videoApp-normal.svg)
                    50% 28%;
            }
        }
        &.sidebar-meetings {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/iconsv2/meeting-normal.svg)
                    50% 28%;
            }
        }

        &.sidebar-tags {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/tags-normal.svg)
                    no-repeat;
            }
        }
        &.sidebar-apps {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/apps-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-switch-people {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_people_normal.svg)
                    no-repeat;
            }
        }

        // Data Integration Link
        &.sidebar-dataintegration {
            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: transparent;
            }
        }

        // Fields Link
        &.sidebar-fields {
            // &::before {
            //     position: absolute;
            //     top: 0;
            //     left: -2px;
            //     content: '';
            //     height: 25px;
            //     width: 25px;
            //     background-size: cover;
            //     background: url(/assets/products/xpmanager/icons/mainmenu/icon_fields_normal.svg) no-repeat;
            // }
        }

        // Live link
        &.sidebar-live {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_live_normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-sponsorship {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/sponsorship-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-projects {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/sponsorship-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-pushengagement {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/notifications-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-pushengagement:hover,
        .active .sidebar-pushengagement {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/iconsv2/notifications-active.svg)
                    50% 28% !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
            }
        }

        &.sidebar-products {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/products-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-switch-sponsorship {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/switch/icon-company.svg) no-repeat;
            }
        }

        // LivePages Link
        &.sidebar-livepages {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background-color: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/livepages-normal.svg)
                    no-repeat;
            }
        }

        // LiveSite Link
        &.sidebar-livesite {
            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_livesite_normal.svg)
                    no-repeat;
            }
        }

        // LiveManager Link

        &.sidebar-chatmoderation,
        &.sidebar-livemanager {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: #d2ddee;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_chat_moderation.svg)
                    no-repeat;
            }
        }

        // Templates Link
        &.sidebar-templates {
            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_livepages_normal.svg)
                    no-repeat;
            }
        }

        // Analytics Link
        &.sidebar-analytics {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/iconsv2/analytics-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-switch-analytics {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_live_normal.svg)
                    no-repeat;
            }
        }

        &.sub-sidebar-sponsors {
            padding-left: 55px;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        &.sidebar-recommendation {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_recommendation.svg)
                    no-repeat;
            }
        }
        &.sidebar-registration {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/registration-normal.svg)
                    no-repeat;
            }
        }
        &.sidebar-livestream,
        &.sidebar-qna {
            padding-left: 55px !important;
        }

        &.sub-sidebar-contentsecurity {
            padding-left: 55px;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        & .sub-sidebar-sponsors :hover,
        &.active .sub-sidebar-sponsors {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        &.sub-sidebar-livepage,
        &.sub-sidebar-chatmoderation,
        &.sub-sidebar-livemanager,
        &.sub-sidebar-livesite {
            padding-left: 55px !important;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        & .sub-sidebar-livepage :hover,
        &.active .sub-sidebar-livepage {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        &.sub-sidebar-packages {
            padding-left: 55px;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        &.sub-sidebar-packages :hover,
        &.active .sub-sidebar-packages {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        &.sub-sidebar-announcements,
        &.sub-sidebar-polls {
            padding-left: 55px;

            &::before {
                position: absolute;
                top: 5px;
                left: 3px;
                content: '';
                height: 16px;
                width: 16px;
                border-radius: 2px;
                background: transparent;
            }
        }

        // &.sidebar-packages {
        //     &::before {
        //         position: absolute;
        //         top: 0;
        //         left: -2px;
        //         content: '';
        //         height: 25px;
        //         width: 25px;
        //         background-size: cover;
        //         background: url(/assets/products/xpmanager/icons/mainmenu/icon_chat_moderation.svg) no-repeat;
        //     }
        // }

        // & .sidebar-packages:hover,
        // &.active .sidebar-packages {
        //     color: #236df2;

        //     &::before {
        //         position: absolute;
        //         top: 0;
        //         left: -2px;
        //         content: '';
        //         height: 25px;
        //         width: 25px;
        //         background-size: cover;
        //         background: url(/assets/products/xpmanager/icons/mainmenu/icon_chat_moderation_active.svg) no-repeat;
        //     }
        // }

        // Enabled venue feature
        &.venue {
            &.sidebar-people,
            &.sidebar-agenda,
            &.sidebar-videos,
            &.sidebar-meetings,
            &.sidebar-analytics,
            &.sidebar-livemanager,
            &.sidebar-badges,
            &.sidebar-channels,
            &.sidebar-virtual {
                &::before {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    content: '';
                    height: 40px;
                    width: 40px;
                    border-radius: 5px;
                    background-size: cover;
                    background: #d2ddee;
                }
            }

            // sub menus
            &.sub {
                padding-left: 40px;

                // color: #C9D4E4;
                &::before {
                    position: absolute;
                    top: 5px;
                    left: 3px;
                    content: '';
                    height: 16px;
                    width: 16px;
                    border-radius: 2px;
                    background: transparent;
                    display: none;
                }

                &.active {
                    color: #000000;

                    &::before {
                        background: transparent;
                    }
                }
            }
        }

        &.sidebar-switch-dashboard {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: url(/assets/switch/icon-dashboard.svg) no-repeat;
            }
        }

        &.sidebar-livepage {
            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_livepages_normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-livemanager {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background-color: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/livemanager-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-companyprofile {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background-color: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/sponsorship-normal.svg)
                    no-repeat;
            }
        }

        &.sidebar-channels {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background-color: #d2ddee;
                background: url(/assets/products/xpmanager/iconsv2/channels-normal.svg)
                    no-repeat;
            }
        }
    }

    .sidebar-recommendation:hover,
    .active .sidebar-recommendation {
        &::before {
            background: url(/assets/products/xpmanager/icons/mainmenu/icon_recommendation_active.svg)
                no-repeat !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
        }
    }

    .sidebar-registration:hover,
    .active .sidebar-registration {
        &::before {
            background: url(/assets/products/xpmanager/icons/mainmenu/registration-active.svg)
                no-repeat !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
        }
    }

    // All styles for specific nav link that is active and hovered
    & a {
        width: 0;
        color: black;

        &:hover {
            -webkit-transition: color 0s;
            -moz-transition: color 0s;
            -o-transition: color 0s;
            transition: color 0s;
            text-decoration: none;
            color: #125dff;
        }

        // If menu is not clicked, return to default colors both .main and .sub <p></p>
        &,
        &.active .sub {
            -webkit-transition: color 0.9s ease-in;
            -moz-transition: color 0.9s ease-in;
            -o-transition: color 0.9s ease-in;
            transition: color 0.9s ease-in;
        }

        // If menu bar is collpsed hide link texts by changing colors
        &.menuclicked,
        &.active .menuclicked {
            color: transparent;
            -webkit-transition: color 0.6s;
            -moz-transition: color 0.6s;
            -o-transition: color 0.6s;
            transition: color 0.6s;
            margin-left: -1000px;
        }

        & .sidebar-switch:hover,
        &.active .sidebar-switch {
            &::before {
                position: absolute;
                top: -9px;
                left: -17px;
                content: '';
                height: 48px;
                width: 55px;
                background-size: cover;
                background: url(/assets/icon-swith-menu-active.png) no-repeat;
            }
        }

        // active switch dashboard
        & .sidebar-switch-dashboard:hover,
        &.active .sidebar-switch-dashboard {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/switch/icon-dashboard-active.svg) 50%
                    28%;
            }
        }

        &.sidebar-livepage:hover,
        &.active .sidebar-livepage {
            color: #236df2;

            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_livepages_colored.svg)
                    no-repeat;
            }
        }

        // Active or Hovered: People link
        & .sidebar-people:hover,
        &.active .sidebar-people {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 4px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/peoples-active.svg)
                    50% 28% !important;
            }
        }

        & .sidebar-virtual:hover,
        &.active .sidebar-virtual {
            &::before {
                // background: #236df2 !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/virtual-active.svg)
                    50% 28% !important;
            }
        }
        & .sidebar-badges:hover,
        &.active .sidebar-badges {
            &::before {
                // background: #236df2 !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/virtual-active.svg)
                    50% 28% !important;
            }
        }

        & .sidebar-agenda:hover,
        &.active .sidebar-agenda {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 4px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/agenda-active.svg)
                    50% 28% !important;
            }
        }

        // Active or Hovered: Data Integration link
        & .sidebar-dataintegration:hover,
        &.active .sidebar-dataintegration {
            color: #236df2;

            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: transparent no-repeat;
            }
        }

        // Active or Hovered: Fields link
        & .sidebar-fields:hover,
        &.active .sidebar-fields {
            color: #236df2;

            // &::before {
            //     position: absolute;
            //     top: 0;
            //     left: -2px;
            //     content: '';
            //     height: 25px;
            //     width: 25px;
            //     background-size: cover;
            //     background: url(/assets/products/xpmanager/icons/mainmenu/icon_fields_colored.svg) no-repeat;
            // }
        }

        // Active or Hovered: Live link
        & .sidebar-live:hover,
        &.active .sidebar-live {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_live_colored.svg)
                    no-repeat !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
            }
        }

        & .sidebar-sponsorship:hover,
        &.active .sidebar-sponsorship {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/sponsorship-active.svg)
                    50% 28%;
            }
        }

        & .sidebar-videos:hover,
        &.active .sidebar-videos {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/videoApp-active.svg)
                    50% 28%;
            }
        }
        & .sidebar-meetings:hover,
        &.active .sidebar-meetings {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/meeting-active.svg)
                    50% 28%;
            }
        }

        & .sidebar-projects:hover,
        &.active .sidebar-projects {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/sponsorship-active.svg)
                    50% 28%;
            }
        }

        // Active or Hovered: LivePages
        & .sidebar-livepages:hover,
        &.active .sidebar-livepages {
            color: #236df2;

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: linear-gradient(135deg, #3c81ff 0%, #0d4cbc 100%);
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
                background: url(/assets/products/xpmanager/iconsv2/livepages-active.svg)
                    50% 28%;
            }
        }

        // Active or Hovered: LiveSite
        & .sidebar-livesite:hover,
        &.active .sidebar-livesite {
            color: #236df2;

            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_livesite_colored.svg)
                    no-repeat;
            }
        }

        // Active or Hovered: LiveManager
        & .sidebar-livemanager:hover,
        &.active .sidebar-livemanager {
            color: #236df2;

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: linear-gradient(135deg, #3c81ff 0%, #0d4cbc 100%);
                background: url(/assets/products/xpmanager/iconsv2/livemanager-active.svg)
                    50% 28%;
            }
        }

        & .sidebar-companyprofile:hover,
        &.active .sidebar-companyprofile {
            color: #236df2;

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: linear-gradient(135deg, #3c81ff 0%, #0d4cbc 100%);
                background: url(/assets/products/xpmanager/iconsv2/sponsorship-active.svg)
                    50% 28%;
            }
        }

        & .sidebar-channels:hover,
        &.active .sidebar-channels {
            color: #236df2;

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                border-radius: 5px;
                background-size: cover;
                background: linear-gradient(135deg, #3c81ff 0%, #0d4cbc 100%);
                background: url(/assets/products/xpmanager/iconsv2/channels-active.svg)
                    50% 28%;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
            }
        }

        // Active or Hovered: Chat Moderation or Live Manager
        & .sidebar-chatmoderation:hover,
        &.active .sidebar-chatmoderation,
        & .sidebar-livemanager:hover,
        &.active .sidebar-livemanager {
            color: #236df2;

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_chat_moderation_active.svg)
                    50% 28%;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
            }
        }

        // Active or Hovered: Templates
        & .sidebar-templates:hover,
        &.active .sidebar-templates {
            color: #236df2;

            &::before {
                position: absolute;
                top: 0;
                left: -2px;
                content: '';
                height: 25px;
                width: 25px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_livepages_colored.svg)
                    no-repeat;
            }
        }

        // Active or Hovered: Analytics link
        & .sidebar-analytics:hover,
        &.active .sidebar-analytics {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/iconsv2/analytics-active.svg)
                    50% 28% !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
            }
        }

        & .sidebar-switch-analytics:hover,
        &.active .sidebar-switch-analytics {
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                border-radius: 5px;
                background: url(/assets/products/xpmanager/icons/mainmenu/icon_live_colored.svg)
                    no-repeat !important;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
            }
        }

        // If menu is clicked, hide texts even active links
        &.menuclicked > p:hover,
        &.active.menuclicked > p,
        &.menuclicked,
        &.active.menuclicked {
            color: transparent;
            -webkit-transition: color 0.9s;
            -moz-transition: color 0.9s;
            -o-transition: color 0.9s;
            transition: color 0.9s;
        }
    }
}

// The hamburger menu for mainMenu
#mainMenu {
    position: relative;
    display: flex;
    width: 100%;
    transition: left 0.5s;
    padding: 18px 10px 10px;
    left: 129px;
    transition: left 0.6s;
    width: 0px;

    &:hover > .span1::before {
        box-shadow: 0 -4px 0 0 #000000, 0 -8px 0 0 #000000 !important;
    }

    &:hover > .span1 {
        background: #000000 !important;
    }

    & .span1 {
        width: 9px;
        height: 2px;
        background: #bdc9dc;
        border-radius: 4px;

        &::before {
            display: block;
            content: '';
            width: 12px;
            height: 2px;
            box-shadow: 0 -4px 0 0 #bdc9dc, 0 -8px 0 0 #bdc9dc;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.menuclicked {
        left: 26px !important;
        transition: left 0.5s;
    }
}

// Tooltips
.tooltipx {
    background: #000000 !important;
    color: white !important;
    height: 25px;
    font-weight: 600;
    font-size: 0.8rem;
    padding-top: 1px !important;
    width: auto;
    border-radius: 4px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    margin-left: 48px !important;
    margin-top: 1px !important;
}

// Second package Tooltip
.rc-tooltip {
    &.tooltipZ {
        left: 52px !important;
    }

    &.tooltipZ .rc-tooltip-inner {
        background: black !important;
        padding: 4px 6px !important;
        font-weight: 600;
        font-size: 0.8rem;
        border-radius: 4px !important;
        //box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
        min-height: unset !important;

        &:hover {
            cursor: pointer;
        }
    }
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: black !important;

    &:hover {
        cursor: pointer;
    }
}
