@import "../../utils/styles/mixins";
@import "../../utils/styles/variables";

.alert-simple {
  position: fixed;
  bottom: 50px;
  font-size: 0.9em;
  text-align: left;
  z-index: 2147483005;

  &__box {
    display: flex;
    align-items:center;
    padding: 10px;
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background: #000;
    color: #fff;
    width: 289px;
    min-height: 68px;

    &.process-wrap {
      background: transparent !important;
      width: 0px;
      box-shadow: unset !important;
      padding-right: 0px;
      color: #2296f387;
      margin-right: -6px;
      min-height: 56px !important;
    }

    &--success, &--error, &--info{
      i{
        width: 32px;
        height: 32px;
        margin-left: 10px;
        position: absolute;
      }
      p{
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: normal;
        padding-left: 60px;
        padding-right: 15px;
        margin:0;
      }
    }
    &--success {
      background-color: #D4EDDA;
      color: #155724;
      position: relative;
      border-left: 4px solid #306D23;
    }

    &--error {
      background: #FFB2B2;
      color: #893C3C;
      position: relative;
      border-left: 4px solid #893C3C;
    }

    &--info{
      background-color: #C5DCFF;
      color: #000;
      position: relative;
      border-left: 4px solid #2196F3;
    }
  }

  &-enter {
    left: 0;
    opacity: 0;
  }

  &-enter-active {
    left: 45px;
    transition: all 0.3s ease;
    opacity: 1;
  }

  &.alert-simple-enter-done {
    left:45px
  }

  &-exit {
    left: 45px;
    opacity: 1;
  }

  &-exit-active {
    left: 0;
    opacity: 0;
    transition: all 0.3s ease;
  }
}
