.bookmarks {
    h2 {
        font-family: 'Montserrat Bold', sans-serif;
        line-height: 19px !important;
        letter-spacing: -0.28px !important;
        font-size: 16px !important;
        text-align: left;
        margin-top: 30px;
        margin-bottom: 14px;
    }

    .bookmark-container {
        background: #ffffff;
        border-radius: 4px;
        overflow: auto;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);

        .heading {
            display: flex;
            align-items: center;
            height: 77px;
            justify-content: center;
            flex-direction: column;
            h3 {
                font-family: 'Open Sans', sans-serif !important;
                line-height: 36px !important;
                letter-spacing: -0.5px !important;
                font-size: 26px !important;
                margin-bottom: -5px;
                color: #000000;
            }
            span {
                color: #b4bfd2;
                font-family: 'Open Sans SemiBold', sans-serif;
                font-size: 12px;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: -0.28px;
            }
        }
        .content {
            table {
                width: 100%;
                margin-top: 10px;
                thead {
                    color: #000000;
                    font-size: 14px !important;
                    border-top: 1px solid #e2e5eb;
                    tr {
                        th {
                            padding: 15px 24px 10px;
                        }
                    }
                }
                tbody {
                    color: #000000;
                    font-size: 12px !important;
                    border-top: 1px solid #e2e5eb;
                    tr {
                        border-bottom: 0 !important;
                        td {
                            padding: 10px 24px !important;
                            font-weight: normal;
                        }
                    }
                }
            }
            .pagination-container {
                display: flex;
                align-items: center !important;
                justify-content: center !important;
                border-top: 1px solid #e2e5eb;
                border-bottom: 1px solid #e2e5eb;
                padding: 15px 0;
                margin-top: 10px;
                .pagination {
                    border: 0;
                }
                .previous,
                .next {
                    display: none !important;
                }
                li.active {
                    background: transparent;
                    border: 0;
                    a {
                        color: #4f5a76 !important;
                        &:hover {
                            background: transparent;
                        }
                    }
                }
                a:hover {
                    background: transparent;
                }
                li {
                    background: transparent;
                    a {
                        color: #b1bbd4 !important;
                    }
                }
            }
            .exports {
                display: flex;
                align-items: center !important;
                justify-content: center !important;
                padding: 15px 0;
                button {
                    border: 0;
                    background: #d2dceb;
                    width: 97px;
                    height: 27px;
                    border-radius: 4px;
                    font-family: 'Open Sans SemiBold', sans-serif;
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: -0.49px;
                    color: #0b1c2c;
                    font-weight: 600;
                }
            }
        }
    }
}
