.venue-store-modal-details {
    .ant-carousel .slick-slide img {
        width: 100%;
    }
    .ant-modal {
        height: 100%;
    }
    .ant-modal-body {
        padding: 64px 48px;
        // min-height: 95vh;
        max-width: 1072px;
        min-height: 752px;
        height: 100%;
        margin-top: 10px;
        margin: 0 auto;
        h2 {
            font-family: 'Montserrat', sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 16px !important;
            line-height: 20px !important;
            letter-spacing: -0.04em !important;
            color: #000000;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 18px;
            width: fit-content;
            span {
                margin-right: 8px;
            }
        }
        padding-bottom: 112px;
        .venue-modal-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &.onsubmit-loading {
                position: relative;
                opacity: 0.6;
                background: rgba(255, 255, 255, 0.7);
                .global-loading-content {
                    position: absolute;
                    z-index: 9999;
                    // left: 50%;
                    top: -13%;
                    // transform: translate(-50%, -50%);
                    height: 70vh;
                    width: 100%;
                    // background: #fff;
                    background: rgba(255, 255, 255, 0.7);
                    img {
                        width: 175px;
                    }
                    p {
                        text-align: center;
                        color: #000;
                        font-size: 18px;
                    }
                }
            }
            &.loading {
                justify-content: center;
                align-items: center;
                margin: 100px 0;
                .ant-spin-text {
                    font-size: 18px;
                }
            }
            .left {
                width: 55%;
                img {
                    width: 100%;
                    object-fit: contain;
                    background-color: white;
                    height: 600px;
                }
                .ant-carousel {
                    width: 496px !important;
                    height: 552px;
                }
            }

            .right {
                width: 47%;
                display: flex;
                flex-direction: column;
                h2 {
                    font-family: 'Montserrat', sans-serif !important;
                    font-style: normal !important;
                    font-weight: bold !important;
                    font-size: 16px !important;
                    line-height: 20px !important;
                    letter-spacing: -0.02em !important;
                    color: #000000;
                    cursor: normal;
                    margin-bottom: 10px;
                }
                .heading {
                    h1 {
                        font-family: 'Montserrat', sans-serif !important;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px !important;
                        line-height: 29px !important;
                        letter-spacing: -0.04em !important;
                        color: #000000;
                        margin: 0;
                    }
                    span {
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 18px;
                        letter-spacing: -0.03em;
                        color: #9ca4b4;
                    }
                }
                .buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin-top: 16px;
                    .btn {
                        font-family: 'Open Sans', sans-serif !important;
                        font-style: normal !important;
                        font-weight: bold !important;
                        font-size: 15px !important;
                        line-height: 40px !important;
                        letter-spacing: -0.02em;
                        width: 202px;
                        height: 48px !important;
                        border: 0;
                        border-radius: 4px !important;

                        &.btn-preview {
                            color: #0b1c2c;
                            background: #e2e9f3;

                            color: #000;
                            vertical-align: middle;
                            padding: 4px 10px !important;
                        }
                        &.btn-primary {
                            padding: 0px 10px !important;
                            span {
                            }
                        }
                    }
                }
                .tags {
                    margin-top: 16px;
                }
                .descrition {
                    margin-top: 16px;
                    p {
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: -0.02em;
                        color: #000000;
                        margin-bottom: 8px;
                    }
                }
                .features,
                .experiences {
                    margin-top: 16px;
                    ul {
                        margin: 0;
                        padding-left: 15px;
                        li {
                            font-family: 'Open Sans', sans-serif;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 19px;
                            letter-spacing: -0.02em;
                            color: #000000;
                        }
                    }
                    p {
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: -0.02em;
                        color: #000000;
                        margin-bottom: 8px;
                    }
                }
            }
            .ant-carousel .slick-dots-bottom {
                bottom: -32px !important;
            }
        }
    }
}
