.poll-container {
    .poll-item {
        padding: 0;
        cursor: default;
        position: relative;

        .poll-background-fill {
            width: 0%;
            height: 100%;
            border-radius: 3px;
            display: flex;
            align-items: center;
            transition: all 0.5s ease;
            position: absolute;
            z-index: 5;
        }

        .poll-background-label {
            z-index: 10;
            position: inherit;
            font-family: Open Sans;
            font-size: 12px;
            font-weight: 400;
            .poll-radio-item {
                max-width: 380px;
                word-wrap: break-word;
            }

        }

        .poll-background-label-percent {
            font-weight: 700;
        }
    }
}