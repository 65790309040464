#sidebarContainer {
  position: fixed;
  width: auto;
  height: auto;
  display: flex;
  top: 0px;

  // Activates if props set to left
  &.pLeft {
    left: 0 !important;
  }

  // Activates if props set to right
  &.pRight {
    right: 0 !important;
  }
}
