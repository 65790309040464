body.modal-open .fr-popup.fr-desktop.fr-active {
    z-index: 2147483144 !important;
}
body {
    &.modal-open {
        .rc-tooltip {
            z-index: 9999999999;
        }
    }
}
#tuiContainer {
    z-index: 2147483644 !important;
}
.flex-mid {
    display: flex;
    align-items: center;
}
.nm {
    margin: 0;
}
.np {
    padding: 0 !important;
}
.block-creator__body {
    .fz-08 {
        font-size: 0.8em;
    }
    .content-padding {
        display: flex;
        align-items: center;
        padding-right: 0;

        label {
            margin: 0;
        }
    }
}
@media (min-width: 768px) {
    .block-creator__body {
        .content-padding {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

.contentChange > button {
    cursor: pointer;
    height: 32px;
    width: 160px;
    border-radius: 4px;
    color: #000000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    background-color: #f8f9fa;
}
.contentChange > .dropdown-menu > .dropdown-item {
    height: 34px;
    width: 175px;
    display: flex;
    align-items: center;
    padding: 8px 15px 8px;
}
.contentChange > .dropdown-menu > .dropdown-item > img {
    height: 24px;
    width: 24px;
    margin-right: 5px;
}

#contentTypeDrpBtn {
    text-align: left;
    position: relative;
    height: 50px;
    width: 175px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
#contentTypeDrpBtn svg {
    position: absolute;
    right: 7px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    height: 10px;
    width: 10px;
}
