@import "../../../utils/styles/variables";
@import "../../../utils/styles/mixins";

.person-editor {
  padding: $general-padding;

  &__logo {
    &-wrap {
      margin: 0 auto 20px;
      width: 148px;
    }

    &-note {
      text-align: center;
    }
  }
}