#spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & .msg {
        margin-top: 10px;
        font-weight: 600;
    }
}
