@import "../../utils/styles/mixins";

.no-match {
  &__content {
    @include centerer;
    width: 100%;
  } 

  img {
    width: 150px;
    margin-bottom: 30px;
  }
}
