.reports {
  .table-responsive {
    @media screen and (max-width: 1150px) {
      margin-bottom: 30px;
    }
  }

  &__data-table {
    margin-bottom: 20px;
    min-width: 1000px;

    th {
      font-size: 0.8em;
      text-align: center;
      word-wrap: break-word;
      background: #555;
      color: #fff;
      border-color: #333;

      label {
        margin-top: 7px !important;
        margin-bottom: 0 !important;
      }

      input[type="text"] {
        font-size: 1em;
        padding: 5px 10px;
        border: none;
        border: 1px solid #333;
        margin-bottom: 0 !important;

        &:focus {
          background-color: rgb(252, 242, 188);
        }
      }
    }
    td {
      font-size: 0.8em;
      word-wrap: break-word;
      vertical-align: middle;
    }
  }

  &__data-table-wrap {
    background: #fff;
    padding: 30px;
    border: 1px solid rgba(46, 91, 255, 0.15);
    border-radius: 5px;

    button,
    .react-bs-table-csv-btn {
      background-color: #5241d1;
      border: 1px solid #5241d1;
      font-size: 0.8em;
      color: #fff;

      img {
        height: 15px;
      }
    }
  }

  &__stats {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    &--mobile {
      background: #fff;
      padding: 20px;
      border: 1px solid rgba(46, 91, 255, 0.15);
      border-radius: 5px;
      margin-bottom: 30px;

      p {
        font-size: 1em;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 650px) {
      display: block;
    }

    &-group {
      align-self: flex-end;
    }

    &-item {
      width: 18%;
      background: #fff;
      padding: 20px;
      border: 1px solid rgba(46, 91, 255, 0.15);
      border-radius: 5px;
      min-height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media screen and (max-width: 650px) {
        width: 100%;
        margin-bottom: 10px;
        min-height: auto;
      }

      h5 {
        font-family: Roboto;
        font-size: 1em;
        font-weight: bold;
        color: #637279;
        margin-bottom: 5px;
      }

      p {
        margin-bottom: 0;
        font-size: 3em;
        font-weight: bold;
        line-height: 1;

        @media screen and (max-width: 650px) {
          font-size: 1.2em;
        }
      }
    }
  }
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;

  .page-item {
    font-size: 0.8em;
  }

  .active {
    .page-link {
      font-weight: bold;
      background: #5241d1;
      border-color: #5241d1;
    }
  }
}
