.Wrap {
    height: inherit;
    display: flex;
    width: 100%;
    height: calc(100vh - 185px);
    flex-direction: column;
    overflow: hidden;

    @media screen and (max-width:760px) {
        height: calc(100vh - 223px)
    }

    h1 {
        margin-bottom: 1em;
    }
}