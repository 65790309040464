@import '../../../../utils/styles/mixins';
@import '../../../../utils/styles/variables';

.user-container {
    @media (min-width: 1200px) {
        max-width: 960px !important;
    }
}

.invite-btn {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #ffffff;
    padding: 6px 12px;
    margin: 0 5px 0 0 !important;
    width: auto !important;
    height: auto !important;
}

.sponsor-dashboard {
    align-items: center;
    height: 100%;

    &__xpmgr {
        display: flex;
        &.sponsor-user-list {
            justify-content: space-between;
            margin-top: 80px !important;
        }
    }

    &__left {
        display: flex;
        align-items: center;
        h1 {
            font-family: 'Montserrat Bold', sans-serif;
            line-height: 34px;
            letter-spacing: -1.15px;
            font-size: 28px;
            color: #000000;
            position: relative;
        }
    }
}

table.user-list-table {
    tr {
        td {
            &:first-child {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}

.dropdown {
    width: auto !important;
    > button {
        gap: 14px;
        > span::before {
            content: 'Display ';
        }
    }
}
