@import "../../../utils/styles/mixins";

.image-preloader {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  background: url("/assets/icon_image.svg") no-repeat center center;
  background-size: 30px 30px;
  background-color: #eee;
  border-radius: 10px;
}
