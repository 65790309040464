
.venues-items {
    &.loading {

    }
    .venue-item {
        background: #FFFFFF;
        border: 1px solid #E0E7FF;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 16px;
        margin: 6px 0;
        h2 {
            font-family: "Montserrat", sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 16px !important;
            line-height: 20px !important;
            letter-spacing: -0.02em !important;
            color: #000000;
        }
        .btn {
            &.btn-preview {
                font-family: 'Open Sans', sans-serif !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 13px !important;
                line-height: 18px !important;
                letter-spacing: -0.02em;
                color: #0B1C2C;

                background: #e2e9f3;
                border: 0;
                padding: 5px 10px!important;
                border-radius: 4px!important;
                color: #000;
                vertical-align: middle;
                margin: 0 5px;
                width: 133px;
                height: 32px;
                margin-bottom: 8px;
            }
            &.btn-plus {
                background: #E3E9F3;
                border-radius: 2px;
                width: 22px;
                height: 20px;
                padding: 0;
                line-height: 18px;
                .fa-plus {
                    font-size: 14px;
                }
            }
        }
        .card-image-placeholder {
            width: 128px;
            height: 84px;
            background: #F3F5FA;
            border-radius: 4px 4px 0px 0px;
        }
        .venue-labels {
            margin-top: 5px;
            label {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.03em;
                color: #313A44;
                margin-bottom: 6px;
            }
        }
        .venue-projects {
            margin-top: 14px;
            label {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.03em;
                color: #313A44;
                margin-bottom: 6px;
            }
        }
        .venue-edits-logs {
            margin-top: 16px;

            span {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.03em;
                color: #313A44;
                margin-right: 20px;
            }
        }
    }
}

.ant-tag {
    background-color: #E1E9F1;
    border-radius: 2px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: #000000;
    padding: 2px 8px;
}
.anticon-close {
    color: #000000 !important;
    font-weight: 600 !important;
}
.anticon {
    vertical-align: 0em;
}
.ant-dropdown-menu {
    .ant-dropdown-menu-item {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #000000;
        &:hover {
            background: #EFF3F6;
        }
        span {
            margin-left: 5px;
        }
    }
    &.details {
        width: 202px !important;
        padding: 0;
        .ant-dropdown-menu-item {
            padding: 10px 24px;
            word-wrap: break-word;
            white-space: normal;
        }
    }
}

.slick-dots-bottom {
    bottom: -20px !important;
    li {
        button {
            width: 16px !important;
            height: 16px !important;
            border-radius: 50% !important;
            background: #E4EAF7 !important;
            opacity: 1 !important;
        }
        &.slick-active {
            width: auto !important;
            button {
                background: #236DF2 !important;
            }
        }
    }
}
