.live-pages-list {
    max-height: 200px;
    overflow: scroll;
}
.modalHead {
    h2 {
        margin-bottom: 5px;
        padding: 0px;
        font-weight: bold;
        font-size: 20px;
        font-family: Montserrat;
    }
    p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        padding: 0px;
        margin: 0px;
        opacity: 0.5;
    }
}
.infoBox {
    padding: 0px;
    margin-bottom: 14px;
    label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
    }
    div {
        background: #ffffff;
        border: 1px solid #dadeeb;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 8px 8px 8px 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        cursor: default;
    }
}
.timeLabel {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
