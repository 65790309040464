.billing-container {
    .billing-block {
        padding: 16px;
        min-height: 100px;
        background: #ffffff;
        border: 1px solid #dce4fd;
        box-sizing: border-box;
        border-radius: 8px;
    }
    .billing-block-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.99);
    }
    .billing-block-content {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        /* light/text-primary-1 */

        color: #181818;
        margin-top: 10px;
    }
    .billing-block-content-link {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #3a8cec;
        margin-top: 35px;
        cursor: pointer;
        img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
    .loader-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
