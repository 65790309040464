.sponsors-company-table {
    overflow: auto;
    .ant-table-thead {
        tr {
            th {
                font-weight: 600;
                font-size: 14px;
                font-family: 'Open Sans';
            }
        }
    }

    .sponsor-company-logo {
        height: 45px !important;
    }

    .sponsors-company-table-row {
        td {
            padding-top: 7px !important;
            padding-bottom: 7px !important;
        }
    }

    .ant-table-pagination {
        margin-right: 30px;
    }
}

.initials-container {
    width: 100px;
    height: 45px;
    border-radius: 5px;
    background: #535A67;
    display: flex;
    align-items: center;
    justify-content: center;

    & .initials-text {
        font-weight: 600;
        color: #fff !important;
        font-size: 17px;
    }

}