.venue-store-modal {
    .ant-modal-body {
        // height: 95vh;
        max-width: 1072px;
        height: 752px;
        margin-top: 10px;
        padding: 46px;
        overflow: hidden;
        h2 {
            font-family: 'Montserrat', sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 24px !important;
            line-height: 29px !important;
            letter-spacing: -0.04em !important;
            color: #000000;
        }
        .venue-modal-content {
            // &.onsubmit-loading {
            //     position: relative;
            //     .ant-spin {
            //         position: absolute;
            //         z-index: 999;
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;
            //         left: auto;
            //         top: -12%;
            //         width: 103%;
            //         height: 95vh;
            //         background: rgba(255,255,255,.7);
            //     }
            // }
            &.onsubmit-loading {
                position: relative;
                opacity: 0.6;
                background: rgba(255, 255, 255, 0.7);
                .global-loading-content {
                    position: absolute;
                    z-index: 9999;
                    // left: 50%;
                    top: -13%;
                    // transform: translate(-50%, -50%);
                    height: 70vh;
                    width: 100%;
                    // background: #fff;
                    background: rgba(255, 255, 255, 0.7);
                    img {
                        width: 175px;
                    }
                    p {
                        text-align: center;
                        color: #000;
                        font-size: 16px;
                    }
                }
            }
            .venue-selections {
                display: flex;
                flex-direction: row;
                margin-bottom: 16px;
                h3 {
                    font-family: 'Montserrat', sans-serif !important;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.03em;
                    color: #b7c6d6;
                    cursor: pointer;
                    margin-right: 24px;
                    &.active {
                        color: #000000;
                        position: relative;
                        cursor: context-menu;
                        &:after {
                            position: absolute;
                            height: 2px;
                            width: 100%;
                            background-color: #000;
                            content: '';
                            bottom: -5px;
                            left: 0;
                        }
                    }
                }
            }
            .store-items-venue {
                // display: flex;
                // flex-wrap: wrap;
                height: 565px;
                overflow: auto;
                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(3, 1fr);
                &.loading {
                    align-items: center;
                    justify-content: center;
                }
                .ant-card {
                    cursor: pointer;
                    border: 1px solid #d2dbf9;
                    border-radius: 4px;
                    margin-right: 32px;
                    margin-bottom: 32px;

                    .ant-card-cover {
                        height: 178px;
                        width: 270px;
                        border-radius: 4px 4px 0px 0px;
                        background: #f3f5fa;
                        img {
                            border-radius: 4px 4px 0px 0px;
                            width: 100%;
                            height: 100%;
                        }
                        .card-image-placeholder {
                            width: 270px;
                            height: 178px;
                            background: #f3f5fa;
                            border-radius: 4px 4px 0px 0px;
                        }
                    }
                    .ant-card-body {
                        padding: 8px;
                        .venue-store-tags {
                            .ant-tag {
                                margin-bottom: 2px;
                            }
                        }
                    }
                }
            }
            .venue-collections {
                .venue-cols {
                    overflow: auto;
                    height: 530px;
                    &.loading {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .venue-item-collection {
        background: #ffffff;
        border: 1px solid #e0e7ff;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 16px;
        margin: 6px 0;
        h2 {
            font-family: 'Montserrat', sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 16px !important;
            line-height: 20px !important;
            letter-spacing: -0.02em !important;
            color: #000000;
        }
        .card-image-placeholder {
            width: 128px;
            height: 85px;
            background: #f3f5fa;
            border-radius: 4px 4px 0px 0px;
            margin-right: 16px;
        }
        .venue-details {
            width: 80%;
            display: flex;
            img {
                margin-right: 16px;
            }
            // @media only screen and (max-width: 1200px) {
            //     width: 50%;
            // }
        }
        .venue-ctas {
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
        }
        .btn {
            &.btn-preview {
                font-family: 'Open Sans', sans-serif !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 13px !important;
                line-height: 18px !important;
                letter-spacing: -0.02em;
                color: #ffffff;

                background: #236df2;
                border: 0;
                padding: 5px 10px !important;
                border-radius: 4px !important;
                vertical-align: middle;
                margin: 0 5px;
                width: 133px;
                height: 32px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.btn-plus {
                background: #e3e9f3;
                border-radius: 2px;
                width: 22px;
                height: 20px;
                padding: 0;
            }
        }
        .venue-labels {
            margin-top: 5px;
            label {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.03em;
                color: #313a44;
                margin-bottom: 6px;
            }
        }
        .venue-projects {
            margin-top: 14px;
            label {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.03em;
                color: #313a44;
                margin-bottom: 6px;
            }
        }
        .venue-edits-logs {
            margin-top: 16px;

            span {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.03em;
                color: #313a44;
                margin-right: 20px;
            }
        }
    }
    .venue-cols {
        h2 {
            font-family: 'Montserrat', sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 18px !important;
            line-height: 20px !important;
            letter-spacing: -0.02em !important;
            color: #000000;
        }
    }
}

.audio-modal {
    width: 85% !important;
    max-width: 1104px;

    .ant-modal-body {
        width: 100%;
        height: 635px;
        margin-top: 45px;
        padding: 0;
        overflow: scroll;
    }

    .ant-modal-content {
        .ant-modal-close {
            .ant-modal-close-x {
                display: block;
                font-size: 16px;
                font-style: normal;
                line-height: 54px;
                text-align: center;
                text-transform: none;
                text-rendering: auto;
                background-color: #000;
                position: absolute;
                margin-left: 10px;
            }
        }
    }
}
