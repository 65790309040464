.analyticsHead {
    margin-bottom: 28px;
}
.analyticsHead .title {
    color: #000000;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1.15px;
    margin-right: 64px;
}
.analyticsHead {
    cursor: pointer;
    & .subTitle {
        color: #b4bfd2;
        font-family: Montserrat;
        margin-right: 30px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.28px;
    }
    & .activeTab {
        color: #000000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.28px;
        margin-right: 30px;
        border-bottom: solid 2px black;
    }
}
.layout__body {
    background: #f3f5fa !important;
}
.noAnalyticsData {
    color: #b4bfd2a3;
    font-family: Montserrat;
    margin-right: 30px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.28px;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
}
.timezoneSelect {
    position: absolute;
    right: 6px;
    top: 12px;
}
.autoRefresh {
    position: absolute;
    left: 6px;
    top: 12px;
}
.ant-dropdown-menu {
    .ant-dropdown-menu-item {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #000000;
        padding: 10px;
    }
}
