.polls
    div[class*='ant-table-wrapper']
    ul[class*='ant-pagination ant-table-pagination'] {
    display: flex;
    justify-content: flex-start;
}
.ant-table-tbody tr td:first-child,
.ant-table-thead tr th:first-child {
    max-width: 250px !important;
}

.sponsors-tabs {
    .ant-tabs-tab {
        padding: 16px;
    }
}
