.radio-box {
  position: relative;
  color: #000 !important;

  &__divider {
    border-top: 1px solid #cfd6f0;
    width: 100%;
    margin: 20px 0;
  }

  input[type="text"] {
    font-size: 0.8rem;
  }

  >label {
    background: #fff;
    border: 1px solid #dadeeb;
    padding: 20px 50px !important;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.13px;
    margin: 5px 0;
    color: #000;

    span {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: normal;
      margin-bottom: 0;
    }
  }

  [type="radio"]:checked+label {
    border: 1px solid #125dff;
  }

  // this allows the actual radio button to be clickable still (although it's hidden)
  // need it to be clickable because its `onChange` attribute is needed
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 99;
    height: 60px;
  }

  [type="radio"]:disabled+label {
    background-color: #dadeeb !important;
    color: #4c4c4c;
    border-color: transparent;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 63px !important;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }

  [type="radio"]:checked+label:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 30px;
    width: 20px;
    height: 20px;
    border: 1px solid #125dff;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 30px;
    width: 20px;
    height: 20px;
    border: 1px solid #979797;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #125dff;
    position: absolute;
    top: 34px;
    left: 24px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
  }
}


.radio-box-no-desc {
  [type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid #979797;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #125dff;
    position: absolute;
    top: 24px;
    left: 24px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:checked+label:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid #125dff;
    border-radius: 100%;
    background: #fff;
  }
}