@import "../../../utils//styles/mixins";

.box-preloader {
  &__title {
    margin-bottom: 20px;
    width: 60%;
    height: 30px;
    background-size: 30px 30px;
    background-color: #e7eaf3;
    border-radius: 5px;
  }

  &__item {
    margin: 0 auto 20px;
    width: 100%;
    height: 100px;
    background-size: 30px 30px;
    background-color: #e7eaf3;
    border-radius: 5px;
  }
}
