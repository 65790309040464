.empty-notification-table {
    min-height: 450px;

    &>div {
        max-width: 390px;
    }

    h2 {
        font-size: 18px !important;
    }

    .empty-ellips {
        height: 116px;
        width: 116px;
        left: 144.5px;
        top: 0px;
        border-radius: 0px;
        background: #e5e9f8;
        border-radius: 100%;

        @media screen and (max-width:"760px") {
            height: 48px !important;
            width: 48px !important;
        }
    }
}

.channels-list-table {
    .ant-list-pagination {
        padding-right: 25px;
    }

    .ant-list-item:hover {
        background-color: #fafafa;
    }

    .channel-title {
        font-family: 'Montserrat', 'Open Sans', sans-serif;
    }

    .channel-expires-text {
        color: #7e7e7e;
        font-size: 11px;
    }
}

.interactionsAnalysisContainer {
    h2.ant-typography {
        font-size: 15px;
    }
}

.ant-list-item-meta-title {
    border: none;

    h4 {
        border: none;
    }
}

.channels-list {
    overflow-y: scroll;
}

.no-channel {
    .no-channel-heading {
        font-family: 'Montserrat', 'Open Sans', sans-serif;
    }

    .no-channel-info {
        font-size: 13px;
    }
}

.channels-list-table {
    .ant-list-pagination {
        padding-right: 25px;
    }

    .ant-list-item:hover {
        background-color: #fafafa;
    }

    .channel-title {
        font-family: 'Montserrat', 'Open Sans', sans-serif;
    }

    .channel-expires-text {
        color: #7e7e7e;
        font-size: 11px;
    }
}

.interactionsAnalysisContainer {
    h2.ant-typography {
        font-size: 15px;
    }
}

.ant-list-item-meta-title {
    border: none;

    h4 {
        border: none;
    }
}

.channels-list {
    overflow-y: scroll;
}

.no-channel {
    .no-channel-heading {
        font-family: 'Montserrat', 'Open Sans', sans-serif;
    }

    .no-channel-info {
        font-size: 13px;
    }
}