.poll-modal-title {
    font-family: 'Montserrat';

}

.white-dot{
    background-color: #fff;
    width: 7px;
    height: 7px;
    border-radius: 25px;
    display: block;
    margin-right: 5px;

}