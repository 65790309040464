@import "../../utils/styles/mixins";

.no-access {
  p {
    font-size: 1rem;
  }

  &__content {
    @include centerer;
    width: 100%;
  }

  img {
    display: block;
  }

  &__page-logo {
    margin: 0 auto 130px;
  }

  &__icon {
    margin: 0 auto 30px;
  }
}
