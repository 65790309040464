.drag-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e8f7;
    padding: 16px;
    cursor: pointer;

    &:first-child {
        border-top: 1px solid #e3e8f7 !important;
    }

    &:hover {
        background: #f6f6f7;
    }
}

.btn-add-option { 
    border: 1px dashed #000!important;
    margin: 13px!important;
    width: -webkit-fill-available;
    font-size: 12px!important;
}  

.draggable-icon-close {
    cursor: pointer;
}