@import '../../../utils/styles/mixins';
@import '../../../utils/styles/variables';

.otp {
    @include centerer;

    text-align: center;

    @media screen and (max-width: 420px) {
        max-width: 100%;
    }

    &__logo {
        text-align: center;
        max-width: 160px;
    }

    &__box {
        max-width: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-bottom: 0;
            margin-bottom: 30px;
            max-width: 160px;
        }
    }

    .otp-box {
        display: flex;
        margin: 30px 0;

        input {
            height: 60px;
            text-align: center;
            margin-right: 10px;
            font-size: 2.2em;
            font-weight: bold;
            max-width: 42px;
            width: calc(100% / 6 - 10px) !important;
            margin-right: 10px !important;
            &:last-child {
                margin-right: 0 !important;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.5em !important;
            }
        }
    }

    .foot-note {
        color: #797978;
        font-size: 12px;
        text-align: center;

        a {
            text-decoration: none;
            color: dodgerblue;
        }
    }
}
