@import '../../../../utils/styles/mixins';

.leads-dashboard {
    margin: 50px 76px 0 76px;
    &__modal-invite-users {
        max-width: 95%;
    }

    button {
        background: #377bf7;
        color: #fff;
        margin-bottom: 10px;
        font-size: 0.8em;

        &:hover {
            color: #fff;
        }
    }

    button[class*='ant-pagination-item-link'] {
        color: #181818;
    }

    &__buy-more {
        padding: 30px;
        text-align: center;

        button {
            background: #377bf7;
            border: none;
            color: #fff;
            margin-bottom: 10px;
            font-size: 1em;

            &:hover,
            &:active,
            &:focus {
                background: #377bf7 !important;
                color: #fff;
            }
        }
    }
    tbody tr td:first-child {
        font-size: inherit;
    }
}
