@import "../../../../components/app/utils/styles/mixins";

.onboarding {
  height: 100%;
  background: #f5f6fc;
  overflow: hidden;

  form {
    text-align: left;
  }

  @media screen and (max-width: 420px) {
    h1 {
      font-size: 1.7em;
    }
  }

  p {
    font-size: 1em;
  }

  button,
  input[type="submit"] {
    background-color: #5241d1;
    border: 1px solid #5241d1;
  }

  &__header {
    text-align: center;
    max-width: 900px;
    margin: 50px auto 10px;
  }

  &__welcome {
    p {
      margin-bottom: 100px;
    }
  }

  &__last-panel-chat {
    text-align: right;
    margin-bottom: 50px;
  }

  &__panel {
    margin: 50px auto;
    max-width: 600px;
    padding: 50px;
    text-align: center;

    @media screen and (max-width: 420px) {
      margin: 15px;
      padding: 10px;
      margin-bottom: 50px;
    }
  }

  &__footer {
    button,
    input[type="submit"] {
      min-width: 130px;

      @media screen and (max-width: 500px) {
        width: 100%;
        padding: 15px;
        font-size: 1.3em;
      }
    }

    @media screen and (max-width: 420px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: #fff;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
    }
  }

  &__create-profile {
    // @include centerer;
  }
}
