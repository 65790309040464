@import "../../../../../utils/styles/mixins";
@import "../../../../../utils/styles/variables";

.header-showcase {
  &__icon {
    margin-right: 7px;
  }
  &__right-menu {
    display: flex;
    justify-content: flex-end;

    button,
    a.btn {
      margin-left: 10px;
      font-size: 0.9em;

      span {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  &__menu {
    font-weight: bold;

    &--active {
      border-bottom: 3px solid #1977ee;
    }

    a {
      display: inline-block;
      color: #333;
      margin-right: 20px;

      &:hover {
        color: #1977ee;
        text-decoration: none;
      }
    }
  }
}
