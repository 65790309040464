.previewer {
  padding: 20px;
  background: #f3f5fa;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  &__refresh {
    cursor: pointer;
    z-index: 99;
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
      width: 12px;
      margin-right: 7px;
    }

    &:hover {
      color: #236df2;
    }
  }

  &__selector {
    flex: 1;
    justify-content: center;
    transform: translateX(50px);
    display: flex;
    margin-bottom: 20px;
    width: 100%;

    span {
      margin: 0 5px;
      padding: 5px 7px;
      border-radius: 5px;
    }
  }

  &__viewport {
    cursor: pointer;

    &--selected {
      background: #dfe9ff;
    }
  }

  iframe {
    height: 100vh;
    border: none;
    margin: 0 auto;
    transition: width 300ms;
  }
}
