@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');

@import '../../utils/styles/variables';
@import '../../utils/styles/mixins';

.layout {
    min-height: 100vh;
    background: #f4f6fc;

    &--xpmanager {
        background: #ffffff;
    }

    &__body {
        padding: 40px;
        overflow: hidden;
        margin-left: 0px;

        &.allow-fixed {
            margin-top: 55px;
        }

        &.transFx {
            -webkit-transition: margin-left 0.5s ease-out;
            -moz-transition: margin-left 0.5s ease-out;
            -o-transition: margin-left 0.5s ease-out;
            transition: margin-left 0.5s ease-out;
        }

        @media screen and (max-width: 780px) {
            padding: 30px 20px;
        }
    }
}

.chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}
