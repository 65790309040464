.imageUploadComponent {
    .bottom-part-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2;

        span[class='ant-select-selection-item'] {
            overflow: visible;
        }
    }
}
