.addSpeaker {
    height: 48px;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.speakerContainer {
    margin-top: 22px;
    position: relative;
}

.speakerContainer div[class*='ant-list-pagination'] {
    display: flex;
}

.addSpeaker div {
    color: #8798ad;
    font-family: 'Open Sans';
    font-size: 12px;
    letter-spacing: -0.34px;
    line-height: 17px;
    cursor: pointer;
}

.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 13px;
    width: 13px;
}

.speakerForm {
    margin: 41px 30px 30px;
}

.speakerForm h1 {
    color: #000000;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.63px;
    line-height: 27px;
}

.formLabel {
    color: #1e1f21;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 17px;
}

.mt14 {
    margin-top: 14px;
}

.previewImgBox {
    box-sizing: border-box;
    height: 113px;
    width: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #dadeeb;
    border-radius: 5px;
    background-color: #f3f5fa;
}

.previewImgBox img {
    height: 113px;
    width: 139px;
    object-fit: contain;
}

.selectFile input[type='file'] {
    display: none;
}

.fileOptions {
    color: #125dff;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    line-height: 18px;
    margin-top: 5px;
    cursor: pointer;
}

.fileOptions span {
    margin-right: 15px;
}

.lightTxt {
    color: #798597;
    font-family: 'Open Sans';
    font-size: 11px !important;
    letter-spacing: 0;
    margin-top: 3px;
}

.speakerFormButtons {
    height: 90px;
    margin-top: 52px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: #e7e8eb 1px solid;

    @media screen and (max-width:640px) {
        justify-content: space-between;

    }
}

.speakerFormButtons button {
    height: 32px;
    margin-right: 10px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.53px;
    line-height: 18px;
}

.addSpeaker img {
    height: 14px;
    width: 14px;
    margin-right: 4px;
}

.speakerCard {
    min-height: 131px;
    position: relative;
    width: 100%;
    margin-top: 13px;
    padding: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.speakerCard .biography {
    color: #2d2e31;
    font-family: 'Open Sans';
    font-size: 12px !important;
    letter-spacing: 0;
    line-height: 19px;
    word-wrap: break-word;
}

.speakerCard img {
    height: 42px;
    width: 42px;
    border-radius: 21px;
    background-color: #98999e;
    object-fit: cover;
}

.speakerTitle {
    height: 45px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ml15 {
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
    }
}

.speakerTitle .name {
    color: #202123;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.32px;
    line-height: 22px;
}

.speakerTitle .jobTitle {
    color: #939ea5;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.23px;
    line-height: 11px;
}

.ml15 {
    margin-left: 12px;
}

.speakerEditBtns {
    position: absolute;
    right: 0px;
    top: 23px;
}

.speakerEditBtns span {
    color: #939ea5;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.21px;
    line-height: 11px;
    margin-right: 20px;
    cursor: pointer;
}

.speakerEditBtns span:hover {
    color: #1683ec;
}

.speakerTitle .noImage {
    height: 42px;
    width: 42px;
    border-radius: 21px;
    background-color: #b5bcc7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 18px;
}

@media screen and (max-width: 640px) {
    .speakerCard {
        border-radius: 9px;
    }

    .speakerCard .biography {
        font-size: 16px !important;
    }

    .speakerCard .biography p {
        font-size: 16px !important;
    }

    .speakerContainer {
        margin: 0px;
    }
}