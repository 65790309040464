.virtual {
    h1 {
        color: #000000;
    }

    .venue-details-wrapper {
        border: 1px solid #e0e7ff;
        padding: 24px;
        background: #ffffff;

        .venue-item-collection {
            background: #ffffff;
            box-sizing: border-box;
            border-radius: 4px;
            border-bottom: 1px solid #e4eaf7;
            padding-bottom: 10px;

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            h2 {
                font-family: 'Montserrat', sans-serif !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 16px !important;
                line-height: 20px !important;
                letter-spacing: -0.02em !important;
                color: #000000;
            }

            .card-image-placeholder {
                width: 128px;
                height: 85px;
                background: #f3f5fa;
                border-radius: 4px 4px 0px 0px;
                margin-right: 16px;
            }

            .venue-details {
                width: 80%;
                display: flex;

                img {
                    margin-right: 16px;
                }

                // @media only screen and (max-width: 1200px) {
                //     width: 50%;
                // }
            }

            .venue-ctas {
                width: 20%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
            }

            .btn {
                &.btn-preview {
                    font-family: 'Open Sans', sans-serif !important;
                    font-style: normal !important;
                    font-weight: bold !important;
                    font-size: 13px !important;
                    line-height: 18px !important;
                    letter-spacing: -0.02em;
                    color: #0b1c2c;
                    background: #e2e9f3;
                    border: 0;
                    padding: 5px 10px !important;
                    border-radius: 4px !important;
                    vertical-align: middle;
                    margin: 0 5px;
                    width: 133px;
                    height: 32px;
                    margin-bottom: 8px;
                }

                &.btn-plus {
                    background: #e3e9f3;
                    border-radius: 2px;
                    width: 22px;
                    height: 20px;
                    padding: 0;
                }
            }

            .venue-labels {
                margin-top: 5px;

                label {
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.03em;
                    color: #313a44;
                    margin-bottom: 6px;
                }
            }

            .venue-projects {
                margin-top: 14px;

                label {
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.03em;
                    color: #313a44;
                    margin-bottom: 6px;
                }
            }

            .venue-edits-logs {
                margin-top: 16px;

                span {
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.03em;
                    color: #313a44;
                    margin-right: 20px;
                }
            }

            .venue-more-actions {
                span {
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: -0.03em;

                    color: #9ca4b4;
                }
            }
        }
    }

    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px !important;

        h2 {
            font-family: 'Montserrat SemiBold', sans-serif;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: -0.04em;
            color: #000000;
            margin: 24px 0;
        }

        .circle {
            width: 116px;
            height: 116px;
            background: #e5e9f8;
            border-radius: 50%;
        }

        .row {
            width: 593px;
            height: 89px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 32px;
            background: #ffffff;
            border: 1px solid #e0e7ff;
            box-sizing: border-box;
            border-radius: 4px;
            margin-bottom: 16px;

            .column {
                h4 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.03em;
                    color: #000000;
                    margin: 0;
                    padding: 0;
                    border: none;
                }

                span {
                    font-family: 'Open Sans', sans-serif !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 143.87%;
                    letter-spacing: -0.02em;
                    color: #313a44;
                }

                &.buttons {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    flex-direction: row;
                }
            }
        }
    }

    .btn {
        &.btn-primary {
            font-family: 'Open Sans', sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 18px !important;
            letter-spacing: -0.02em;
            color: #ffffff;

            // background: #e2e9f3;
            border: 0;
            padding: 5px 10px !important;
            border-radius: 4px !important;
            vertical-align: middle;
            // margin: 0 5px;
            width: 79px;
            height: 32px;
            margin-left: 5px;
        }

        &.btn-preview {
            font-family: 'Open Sans', sans-serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 18px !important;
            letter-spacing: -0.02em;
            color: #0b1c2c;

            background: #e2e9f3;
            border: 0;
            padding: 5px 10px !important;
            border-radius: 4px !important;
            vertical-align: middle;
            // margin: 0 5px;
            width: 103px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        &.browse {
            width: auto;
        }

        // &.btn-plus {
        //     background: #E3E9F3;
        //     border-radius: 2px;
        //     width: 22px;
        //     height: 20px;
        //     padding: 0;
        //     line-height: 18px;
        //     .fa-plus {
        //         font-size: 14px;
        //     }
        // }
    }
}