.notification-preview-container {
    .window-icons {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #D6DDE0;
    }

    h1 {
        color: #DCDFE5;
    }
}