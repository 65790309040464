.visitorAnalysisContainer {
    & .visitorNote {
        position: absolute;
        width: 22%;
        height: 20px;
        top: 110px;
        right: 40px;
        color: #414141;
        opacity: 0.8;
    }

    & .visitLiveBox {
        min-height: 56px;
        width: 100%;
        border-radius: 4px;
        position: relative;
        background-color: #ffffff;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);

        &.controls {
            display: flex;

            .visitor-live-page-search-tooltip {
                margin-top: 24px;
                margin-left: -24px;
            }
        }

        & .visitCounter {
            display: inline-block;
            width: 33.33%;
            height: 100%;

            & .total {
                color: #000000;
                font-family: 'Open Sans';
                font-size: 24px;
                font-weight: 600;
                letter-spacing: -0.55px;
                line-height: 33px;
                text-align: center;
            }

            & .unique {
                color: #858ea4;
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.28px;
                line-height: 17px;
                text-align: center;
            }

            & .users {
                color: #858ea4;
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.28px;
                line-height: 17px;
                text-align: center;
            }
        }

        & .ant-select {
            color: #000000;
            padding: 5px;
            font-family: 'Open Sans';
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.42px;
            min-width: 200px;
            height: 100%;
            border: none;

            .ant-select-selector {
                height: 100%;
                border: none;

                .ant-select-selection-search {
                    .ant-select-selection-search-input {
                        height: 100%;
                    }
                }

                .ant-select-selection-item {
                    height: 100%;
                    line-height: 46px;
                }
            }

            .ant-select-arrow {
                color: #000000;
            }

            .livepage-search-load-more-btn {
                &.ant-btn {
                    color: #1890ff;
                    background: #ececec;
                    width: 100%;

                    &:before {
                        background: #ececec;
                    }
                }
            }
        }

        & .visitorDate {
            position: absolute;
            right: 8px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            height: 32px;
            display: inline-block;

            .ant-picker {
                .ant-picker-range-separator {
                    .ant-picker-separator {
                        .anticon {
                            &.anticon-swap-right {
                                vertical-align: middle;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }
    }

    & .visitorsCard {
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        margin-top: 10px;
        position: relative;

        & .users {
            color: #000000;
            font-family: 'Open Sans';
            font-size: 24px;
            font-weight: 600;
            letter-spacing: -0.55px;
            line-height: 33px;
            text-align: center;
        }

        & .visitText {
            color: #858ea4;
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.28px;
            line-height: 17px;
            text-align: center;
            text-align: center;
        }

        & .visitorsTable {
            & table {
                margin-top: 75px;
            }

            & table tr {
                text-align: center;
            }

            & table thead tr th {
                max-width: 150px;
                padding: 0.3rem;
                word-break: break-word;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            & table tbody tr {
                border: none;
            }

            & table tbody tr td {
                font-family: 'Open Sans';
                font-size: 12px;
                max-width: 150px;
                padding: 0.3rem;
                word-break: break-word;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-wrap;
            }

            & table tr th {
                color: #1e1f21;
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: bold;
                letter-spacing: -0.49px;
                line-height: 17px;
            }

            & .paginationBar {
                display: flex;
                justify-content: center;
                height: 35px;
                border-top: 1px solid rgb(226, 229, 235);

                & ul {
                    color: #ccd2e3;
                    border: none;

                    & .active,
                    & .active:hover {
                        background: none;

                        & a,
                        & a:hover {
                            background: none;
                            color: black;
                        }
                    }

                    & li {
                        & a:hover {
                            background: none;
                            color: black;
                        }
                    }
                }
            }
        }
    }
}

.exportButton {
    padding: 20px;

    & span {
        height: 27px;
        width: 97px;
        border-radius: 4px;
        background-color: #d2dceb;
        color: #0b1c2c;
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.49px;
        line-height: 17px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        bottom: 15px;
        right: 0px;
        left: 0px;
        margin: auto;
    }
}
