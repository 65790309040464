.invite-users {
    border: 1px solid #d5ddf1;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;


    p {
        margin-bottom: 0;
        font-size: 14px;
    }

    @media screen and (max-width:640px) {
        border: none;
        font-family: 'Open Sans', sans-serif;

        h2 {
            font-size: 16px !important;
        }

    }
}