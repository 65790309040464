.dropdown-panel {
    width: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
    padding: 8px 10px 10px 10px;
    background: #f5f5f5;
    position: absolute;
    top: 50px;
    right: 20px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999;

    span {
        color: #333;
        font-size: 0.8rem;
        padding: 5px 7px;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        display: block;

        &:hover {
            background: #eee;
        }
    }

    &-enter {
        top: 0;
    }

    &-enter-active {
        top: 50px;
        transition: top 0.3s ease;
    }

    &-exit {
        top: 50px;
        opacity: 1;
    }

    &-exit-active {
        top: 0;
        opacity: 0;
        transition: all 0.3s ease;
    }
}
