body {
    overflow: auto !important;
}

.App {
    width: 100%;
    height: 100%;

    .Skeleton-Loading {
        display: flex;
        flex: 1 1;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

[disabled],
.disabled {
    cursor: not-allowed;
}

.pointer {
    cursor: pointer !important;
}

.pointer-not-allowed {
    cursor: not-allowed !important;
}

.bg-grey {
    background-color: #e2e9f3 !important;
}

.bg-light-grey {
    background-color: #f3f5fa !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.text-dark {
    color: #0b1c2c !important;
}

.text-xs {
    font-size: 0.75em !important;
}

.myxp {
    font-family: 'Open Sans Regular', sans-serif;
    font-size: 12px;

    label.upload-control,
    span.upload-control,
    a.upload-control,
    a.btn-control,
    .background-option {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600 !important;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.53px;
        color: #125dff;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: #000000;
        }
    }

    h2 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.26px;
    }

    h4 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.13px;
        color: #000;
        border-bottom: 0;
        text-transform: none;
    }

    p {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.37px;
    }

    input {
        font-size: 1em !important;
    }

    &.btn {
        height: 32px !important;

        &.btn-primary {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #236df2 !important;
            font-family: 'Open Sans', sans-serif !important;
            font-weight: 400;
            font-size: 13px !important;
            line-height: 18px !important;
            letter-spacing: -0.53px !important;
            padding-left: 20px !important;
            padding-right: 20px !important;

            &:hover {
                background: #377eff !important;
                border: 1px solid #377eff !important;
                text-decoration: none;
            }

            &.large {
                height: 42px !important;
                width: 190px !important;
            }
        }

        &.btn-secondary {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Open Sans', sans-serif !important;
            font-weight: 600;
            font-size: 13px !important;
            line-height: 18px !important;
            letter-spacing: -0.53px !important;
            width: 68px;
            height: 32px;
            color: #000;
            margin: 0 10px;
            background: #e2e9f3;

            &:hover,
            &:active {
                background: #b9c6d8 !important;
            }
        }
    }

    &.btn-cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0b1c2c;
        font-size: 13px;
        letter-spacing: -0.53px;
        font-family: 'Open Sans SemiBold', sans-serif;
        transition: 0.2s ease-out;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            text-decoration: none;
            background: #e2e9f3;
            transition: 0.2s ease-in;
        }
    }

    .text-muted {
        color: #798597 !important;
    }

    .active-filter {
        position: relative;

        &::after {
            position: absolute;
            height: 2px;
            width: 100%;
            background-color: #000;
            content: '';
            bottom: -5px;
            left: 0;
        }
    }

    label.switch {
        width: 31px;
        height: 18px;
        margin: 0;

        input:checked+.slider:before {
            transform: translateX(13px);
        }

        span.slider.round {
            border-radius: 20px;

            &::before {
                height: 14px;
                width: 14px;
            }
        }
    }
}

.project-tooltip {
    width: 260px;

    a {
        color: white;
        text-decoration: underline;

        &:hover {
            color: #dadeeb;
        }
    }
}

h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 800;
}

h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    text-transform: none !important;
    font-size: 14px !important;
}

.dropdown {
    button.btn {
        padding: 0 10px;
    }
}

.dropdown-menu {
    min-width: 6rem !important;
    border: 1px solid #e2e7ec !important;
    box-shadow: 0.7px 0.7px 20px 0 rgba(0, 0, 0, 0.06);

    .dropdown-item {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 12px;
        height: 26px;
        letter-spacing: -0.3px;

        &:hover,
        &:focus,
        &:active {
            color: #000;
            text-decoration: none;
            background-color: #eff3f6;
        }
    }
}

.dropdown-toggle {
    &::after {
        display: none !important;
    }
}

.dropdown-panel {
    width: auto;
    right: 20px;
    top: 40px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #e2e7ec;
    box-shadow: 0.7px 0.7px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 0;

    & span:hover {
        background: transparent;
    }

    & .user-details {
        padding: 10px 18px;

        &_name {
            color: rgb(29, 29, 29);
            font-family: Montserrat, sans-serif;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: -0.26px;
            line-height: 19px;
            padding: 0 !important;
        }

        &_email {
            color: #8897a7;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 12px;
            letter-spacing: -0.26px;
            line-height: 19px;
            padding: 0 !important;
        }
    }

    & .divider {
        box-sizing: border-box;
        height: 0;
        width: 85%;
        border-top: 1px solid #e2e2e2;
        margin: auto;
    }

    & .logout-link {
        color: rgb(0, 0, 0);
        font-weight: 600;
        line-height: 17px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        padding: 0 18px;
        margin: 10px 0;

        span {
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 12px !important;
            line-height: 17px;
            height: 26px;
            letter-spacing: -0.21px;
        }

        &_icon {
            width: 22px;
            height: 22px;
            background: url(/assets/icon_logout.svg) no-repeat;
            background-size: contain;
        }

        &:hover {
            background: #eff3f6;
            color: white;
            border-radius: 0 0 4px 4px;
        }
    }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;

    &:focus {
        box-shadow: none !important;
    }
}

.pagination {
    font-family: 'Open Sans SemiBold', sans-serif !important;
    font-size: 13px !important;
    justify-content: flex-end !important;
    border: 1px solid #e2e7ec;
    width: fit-content;
    margin: 0;
    line-height: 18px !important;
    letter-spacing: -0.53px !important;

    & a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            background: #377eff;
            color: white;
        }
    }

    & li {
        &:first-child a:hover {
            border-radius: 4px 0 0 4px;
        }

        &:last-child a:hover {
            border-radius: 0 4px 4px 0;
        }

        &.active {
            background: #007bff;

            & a {
                color: white;
            }
        }
    }
}

thead {
    font-family: 'Montserrat SemiBold', sans-serif;
    font-size: 12px !important;
    line-height: 15px;
    letter-spacing: -0.49px;
    border-bottom: 1px solid #c5d0da;
    color: #8897a7;

    th {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        cursor: pointer;

        & div {
            display: flex;
            justify-content: space-between;
        }

        &:first-child {
            padding-left: 0;
        }
    }
}

tbody {
    tr {
        border-bottom: 2px solid #e2e7ec;

        td {
            padding-top: 1.5em !important;
            padding-bottom: 1.5em !important;
            font-size: 13px;
            vertical-align: middle !important;

            &:first-child {
                font-size: 15px;
                letter-spacing: 0;
                line-height: 19px;
                font-family: 'Montserrat SemiBold', sans-serif;
                padding-left: 0;

                a,
                span {
                    color: #000000 !important;
                }
            }

            &:first-child,
            &:nth-child(2),
            &:nth-child(4) {
                word-break: break-word;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

table {
    &.ant-picker-content {
        thead {
            font-size: 14px !important;
            line-height: 1.5715;
            border-bottom: none;
        }

        tbody {
            tr {
                border-bottom: none;

                td {
                    padding-top: 3px !important;
                    padding-bottom: 3px !important;
                    font-size: 14px;

                    &:first-child {
                        font-size: 14px;

                        a,
                        span {
                            color: #000000 !important;
                        }
                    }
                }
            }
        }
    }
}

@keyframes zoomIn {
    from {
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.modal-backdrop.show {
    opacity: 0.3 !important;
    background-color: #455564;
}