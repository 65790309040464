.sessionForm {
    height: 100%;
}

.sessionForm h1 {
    padding: 41px 40px 0px;
    color: #000000;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.63px;
    line-height: 27px;
    margin-bottom: 15px;
}

.sortable-helper-index {
    z-index: 999999 !important;
}

.checkboxCustom span:nth-child(2){
    display: block !important;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80%;

    .input-set {
        width: 100%;
        position: relative;
        .radio-group {
            .ant-radio-group {
                width: 100%;
                .ant-space {
                    width: 100%;
                    .ant-space-item {
                        border: 1px solid #dadeeb;
                        border-radius: 4px;
                        padding: 20px;

                        .radio-head {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 19px;
                            display: flex;
                            align-items: center;
                            letter-spacing: -0.02em;
                            color: #000000;
                        }
                        .ant-radio-wrapper {
                            display: flex;
                            width: 100%;
                            align-items: flex-start;
                            span:nth-child(2) {
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                            }
                            .radio-content {
                                width: 100%;
                                border-top: 1px solid #e3e8f7;
                                margin-top: 10px;
                                padding-top: 10px;
                                .input-radio {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    margin-bottom: 5px;
                                    .ant-checkbox-wrapper {
                                       
                                        span {
                                            font-family: Roboto;
                                            font-style: normal;
                                            font-weight: normal;
                                            font-size: 12px;
                                            line-height: 17px;
                                            display: flex;
                                            align-items: center;
                                            letter-spacing: -0.02em;
                                            color: #000000;
                                        }
                                    }
                                    &.flex {
                                        flex-direction: row;
                                        justify-content: space-between;
                                        .input-radio-item {
                                            width: 100%;
                                        }
                                    }
                                    label {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 19px;
                                        display: flex;
                                        align-items: center;
                                        letter-spacing: -0.02em;
                                        color: #000000;
                                    }

                                    input {
                                        width: 100%;
                                        background: #ffffff;
                                        border: 1px solid #dadeeb;
                                        box-sizing: border-box;
                                        border-radius: 4px;
                                        padding: 8px;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .image-upload-container {
            border: 0px;
            min-height: 0px;
            align-items: flex-start;
            .image-upload-button {
                background: #007bff;
                color: #ffffff;
                margin: 0 0 10px;
            }
        }
        .anticon.anticon-info-circle {
            margin-left: 5px;
        }
        &.widget-menu {
            table {
                width: 100%;

                tr {
                    border: none;
                    font-size: 0.8rem;
                }

                td {
                    align-items: center;
                    vertical-align: middle;
                    font-size: 0.8rem;
                    padding: 7px !important;
                }

                &__sortable-helper {
                    display: table !important;

                    td {
                        padding: 7px;
                        opacity: 0.8;
                        background: #dae7ff;
                        align-items: center;
                        vertical-align: middle;

                        input {
                            margin-bottom: 0;
                        }

                        .switch,
                        img {
                            display: none;
                        }
                    }
                }
            }
        }

        span.error {
            color: red;
            font-size: 10px;
            font-weight: 500;
        }

        label {
            color: #1e1f21;
            letter-spacing: -0.3px;
            line-height: 17px;
            font-size: 12px;
            font-weight: unset;
            font-weight: 600;

            small {
                color: red;
                font-size: 14px;
            }
        }

        .selected-speakers {
            display: flex;
            flex-direction: column;

            tr td {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .speaker-d {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #dadeeb;
                padding: 5px;
                margin-bottom: 3px;
                border-radius: 4px;
                cursor: pointer;

                .details {
                    img,
                    .speaker_profile {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background: #e2e9f3;
                        width: 28px;
                        height: 28px;
                        margin-right: 5px;
                        border-radius: 3px;
                    }
                    span.name {
                        text-align: center;
                    }
                    span {
                        color: #000000;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: -0.2px;
                        font-family: 'Montserrat SemiBold', sans-serif;
                        font-weight: bold;
                        display: inline-block;
                        width: 280px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                    }
                }

                .speaker-action {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .speaker-role {
                        margin-right: 30px;

                        span {
                            margin-right: 5px;
                            font-size: 12px;
                            color: #1e1f21;
                            font-weight: bold;
                        }

                        select {
                            padding: 5px;
                            width: 106px;
                            border: 1px solid #dadeeb;
                            border-radius: 4px;
                            font-size: 12px;
                        }
                    }

                    img {
                        cursor: pointer;
                        width: 14px;
                        height: 15px;
                        fill: #dadeeb;

                        &.remove-speaker {
                            margin-right: 10px;
                        }

                        &.drag-speaker {
                            height: 18px;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }

        .tags-items {
            background-color: #ffffff;
            position: fixed;
            width: 581px;
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            margin-top: 2px;
            border: 1px solid #e2e4e7;

            &.tag {
                margin-left: -31.25rem;
                right: 0;
            }
            input {
                margin-bottom: 10px !important;
            }
            .speakers {
                width: 100%;
                display: flex;
                flex-direction: column;

                &.has-item {
                    overflow-x: auto;
                    height: 200px;
                }

                .select-speaker {
                    font-size: 12px;
                }

                .no-speaker {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 10px 0;

                    span.text {
                        color: #b7c3cf;
                        font-size: 16px;
                        letter-spacing: -0.4px;
                    }

                    span.create {
                        color: #0080ff;
                        font-size: 14px;
                        letter-spacing: -0.4px;
                    }
                }

                .speaker-popup {
                    display: block;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 4px 0;
                    padding: 2px 0;
                    cursor: pointer;

                    &:hover {
                        background: #e2e9f3;
                    }

                    span {
                        width: 100%;
                        text-align: center;
                        font-weight: bold;
                        font-size: 12px;
                    }

                    img,
                    .speaker_profile {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background: #e2e9f3;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-transform: uppercase;

                        span.name {
                            font-weight: bold;
                            background: transparent;
                            text-align: center;
                        }
                    }
                }
            }

            span.tag {
                cursor: pointer;
                padding: 1px 5px;
                height: 20px;
                margin-left: 5px;
                margin-bottom: 5px;
                border-radius: 3px;
                background: #e2e9f3;
                font-size: 12px;
            }
        }

        &.row {
            margin-left: 0;
            margin-right: 0;

            .column {
                width: 68%;
                margin-right: 15px;

                .date,
                .time {
                    .react-datepicker-wrapper {
                        width: 100%;

                        .react-datepicker__input-container {
                            width: 100%;

                            input {
                                display: flex;
                                width: 100%;
                                font-weight: 400;
                                line-height: 1.5;
                                border-radius: 0.25rem;
                                padding: 0.375rem 0.75rem;
                                border: 1px solid #dadeeb;
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &.time {
                    width: 29%;
                    margin-right: 0;
                }
            }
        }

        .addSpeaker:focus,
        .btn-primary.dropdown-toggle:focus {
            background-color: #e2e9f3;
            color: #0b1c2c;
            outline: none;
        }

        .dropdown-menu.show {
            box-shadow: none;
            border: 0 !important;

            .tags-items {
                margin-top: -0.2rem !important;
            }
        }

        .addSpeaker {
            border-radius: 4px;
            background-color: #e2e9f3 !important;
            border: 0;
            color: #0b1c2c;
            height: 36px;
            font-size: 12px;

            .show > .btn-primary.dropdown-toggle {
                color: #0b1c2c !important;
            }

            button {
                height: 36px;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .addTag {
            border-radius: 4px;
            background-color: #e2e9f3 !important;
            border: 0;
            padding: 0px 5px 2px;
            margin-top: 3px;
            img {
                width: 14px;
                margin-right: 0;
            }
        }

        .input-group {
            border: 1px solid #dadeeb;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 3px;
            min-height: 2rem;
            border-radius: 4px;

            .selected-tags {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 94%;
                .tag-placeholder {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    margin-left: 10px;
                    color: #a2a2a2;
                }
                .selectedTag {
                    margin-bottom: 5px;
                    padding: 1px 5px;
                    height: 20px;
                    margin-left: 5px;
                    border-radius: 3px;
                    background: #e2e9f3;

                    img {
                        cursor: pointer;
                        width: 8px;
                        margin: 0 4px;
                    }
                }
            }

            .form-control {
                border-right: 0;
            }

            .add-tag {
                position: absolute;
                right: 3px;
            }

            .input-group-append {
                background-color: #ffffff;
                border-radius: 0 0.25rem 0.25rem 0;

                .input-group-text {
                    cursor: pointer;
                    border: 1px solid #dadeeb;
                    border-radius: 5px;
                    padding: 0.2rem 0.5rem;
                    margin: 4px;

                    img {
                        width: 10px;
                        height: 10px;
                    }

                    &:hover {
                        background-color: #dadeeb;
                    }
                }
            }
        }
    }

    .session-details {
        padding: 0px 40px 0px;
    }

    .session-footer {
        // position: absolute;
        // bottom: 0;
        width: 100%;
        // height: 150px;
        border-top: 1px solid #e7e8eb;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 20px;
        text-align: right;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        button {
            font-size: 13px;
            margin-right: 5px;
            letter-spacing: -0.5px;

            &.btn-secondary {
                background-color: #e2e9f3;
                color: #0b1c2c;
            }
        }
    }
}

// Date custom css
.react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 1px !important;
    z-index: 999999;
}

.react-datepicker__header {
    background: transparent;
    border-bottom: 0;
    padding-top: 15px;
}

.react-datepicker__month-container {
    width: 406px;
}

.react-datepicker__month {
    padding-bottom: 15px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    margin-top: 0px;
    display: none;
}

.react-datepicker {
    border: 1px solid #dadeeb;
}

// Time
.react-datepicker__time-container,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 172px;
}
