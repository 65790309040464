// Inline GlobalSidebar
#globalSidebar {
  position: relative;
  //box-shadow: 38px 0 82px 1px #F6F8FD;
  box-shadow: -8px 0 60px 1px #f1f1f1;
  padding-top: 6px;
  overflow: auto;
  padding-bottom: 40px;
  &.pRight {
    box-shadow: -10px 0 82px 2px #F6F8FD !important;
  }
}

