.blur-xs {
    -webkit-filter: blur(1px);
    filter: blur(1px);
    transition: filter 2s ease-in-out;
}
.blur-none {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    transition: filter 2s ease-in-out;
}

.overlay {
    background: -webkit-repeating-linear-gradient(
        145deg,
        #d8e2dc 0.1px,
        #f8f9fa 0.2px,
        #f8f9fa 1.1px,
        #d8e2dc 1.2px,
        #d8e2dc 2px
    );
    background: repeating-linear-gradient(
        -55deg,
        #d8e2dc 0.1px,
        #f8f9fa 0.2px,
        #f8f9fa 1.1px,
        #d8e2dc 1.2px,
        #d8e2dc 2px
    );
    left: -46px;
    opacity: 0.4;
    width: calc(100% + 200px);
    -webkit-animation-name: MOVE-BG;
    -webkit-animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;

    animation-name: MOVE-BG;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @-webkit-keyframes MOVE-BG {
        from {
            -webkit-transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(46px);
        }
    }

    @keyframes MOVE-BG {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(46px);
        }
    }
}

.overlay-fast {
    -webkit-animation-duration: 0.25s;
    animation-duration: 0.25s;
}

.upgrade {
    left: 0;
    opacity: 1;
}

.still {
    top: 0;
    filter: blur(0px);
    transition: opacity 1s ease-in-out, filter 1s ease-in-out,
        top 1s ease-in-out;
}

.moving {
    top: 100%;
    filter: blur(4px);
    opacity: 0.25;
    transition: opacity 2s ease-in-out, filter 2s ease-in-out,
        top 2s ease-in-out;
}

.deselect {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.animate-bounce-upgrade {
    animation: bounce 1s infinite;
    backdrop-filter: blur(4px);

    @keyframes bounce {
        0%,
        100% {
            transform: translateY(-15%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
}

.eye {
    scale: 1;
    transition: scale 0.5s ease-in-out;
    &:hover {
        scale: 1.2;
    }
}

.shine {
    &:hover:after {
        content: '';
        top: 0;
        transform: translateX(100%);
        width: 100%;
        height: 32px;
        position: absolute;
        z-index: 1;
        animation: slide 1s infinite;

        background: -moz-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
        ); /* FF3.6+ */
        background: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(0%, rgba(255, 255, 255, 0)),
            color-stop(50%, rgba(255, 255, 255, 0.8)),
            color-stop(99%, rgba(128, 186, 232, 0)),
            color-stop(100%, rgba(125, 185, 232, 0))
        ); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
        ); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
        ); /* Opera 11.10+ */
        background: -ms-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
        ); /* IE10+ */
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
        ); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
    }

    @keyframes slide {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
}

.marketplace-overlay {
    height: calc(100% - 68px);
}