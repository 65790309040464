@import "../../../../utils/styles/mixins";

.selector {
  height: 500px;

  &__content-wrap {
    margin-top: 55px;
    line-height: 1.2em;
  }

  &__grid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 120px 120px;
    grid-gap: 20px;
    background-color: #fff;
    color: #444;

    &-wrap {
      @include centerer;
    }

    &__selected {
      background: #1977ee;
    }

    img {
      margin-bottom: 10px;
    }

    &-item {
      background-color: #e1e7fa;
      color: #fff;
      border-radius: 10px;
      padding: 15px;
      color: #8798ad;
      text-align: center;
      line-height: 1;
      font-size: 0.8em;
      height: 110px;
      cursor: pointer;
      position: relative;

      background-repeat: no-repeat;
      background-position: center 30px;
      background-size: 30px 30px;

      &--pdf {
        background-image: url("/assets/icon_pdf_gray.svg");
        transition: all 200ms ease-out;

        &:hover {
          background-image: url("/assets/icon_pdf_white.svg");
        }
      }

      &--video {
        background-image: url("/assets/icon_video_gray.svg");

        &:hover {
          background-image: url("/assets/icon_video_white.svg");
        }
      }

      &--image {
        background-image: url("/assets/icon_image_gray.svg");

        &:hover {
          background-image: url("/assets/icon_image_white.svg");
        }
      }

      &--post {
        background-image: url("/assets/icon_rich_text_gray.svg");

        &:hover {
          background-image: url("/assets/icon_rich_text_white.svg");
        }
      }

      &:hover {
        background-color: #1977ee;
        color: #fff;
      }
    }
  }
}
