.myxp-prompt {
    .modal-content {
        max-width: 444px;
        max-height: 284px;
        height: 284px;
        border: 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        .ant-progress-line{
            font-size: 3px;
        }

        .ant-progress-success-bg, .ant-progress-bg{
            border-radius: 0;
        }

        .ant-progress-inner{
            border-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .modal-header {
            justify-content: center;
            border: 0;
            padding: 42px 0 13px;

            .closeButton {
                position: absolute;
                right: 24px;
                top: 15px;
                cursor: pointer;
                height: 20px;
                width: 20px;

                &:before {
                    position: absolute;
                    content: '';
                    border-left: 2px solid #969696;
                    height: 20px;
                    bottom: 0;
                    right: 0;
                    width: 0;
                    transform: rotate(135deg);
                    &:hover {
                        border-color: #b9c6d8;
                    }
                }
                &:after {
                    position: absolute;
                    content: '';
                    border-left: 2px solid #969696;
                    height: 20px;
                    bottom: 0;
                    right: 0;
                    width: 0;
                    transform: rotate(45deg);
                    &:hover {
                        border-color: #b9c6d8;
                    }
                }
            }
            .lockBg {
                background: rgb(233, 245, 219);
                background: radial-gradient(
                    circle,
                    rgba(233, 245, 219, 1) 0%,
                    rgba(136, 212, 171, 1) 35%,
                    rgba(70, 157, 137, 1) 100%
                );
            }
        }
        .modal-body {
            display: flex;
            justify-content: center;
            align-items: center;
            //padding-top: 10px !important;
            padding-left: 50px !important;
            padding-right: 50px !important;
            text-align: center;

            .prompt-message {
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: 15px !important;
                line-height: 20px;
                letter-spacing: normal;
                margin-bottom: 8px;
                text-align: center;
            }

            .prompt-description {
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: -0.1px;

                &.left {
                    text-align: left;
                }
            }
        }
        .modal-footer {
            display: flex;
            justify-content: center;
            border-top: unset !important;
            padding-bottom: 40px;

            .myxp {
                &.btn-cancel {
                    background: #e2e9f3;
                    width: 146px;
                    &:hover {
                        background: #b9c6d8;
                    }
                }

                &.btn-primary {
                    width: 146px;
                    padding: 0 !important;
                }
                &.opactiy-50{
                    opacity: 0.5;
                }
            }
        }
    }

    .profile-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-size: 12px;
        background-color: #e4eaf7;
        float: right;
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        img {
            width: auto;
            height: 100%;
            max-width: none;
        }

        span {
            color: #000;
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            font-size: 50px;
        }
    }

    @media (min-width: 576px) {
        &.modal-sm {
            max-width: 444px !important;
        }
    }
}

.myxp-prompt-minimize {
    animation: slide-up 0.3s ease-in-out;

    .myxp-prompt-glow {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 140%;
            height: 280%;
            background: linear-gradient(#fff6cc, #eeba0b);
            animation: animate 5s linear infinite;
            border-radius: 0.5rem;
            z-index: -1;
        }

        &:after {
            content: '';
            position: absolute;
            inset: 4px;
            background: #ffffff;
            border-radius: 0.5rem;
            z-index: -1;
        }
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(-70%);
        opacity: 0;
        filter: blur(10px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
        filter: blur(0);
    }
}
@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}