@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Montserrat:wght@400,700,800|Roboto:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import './components/app/utils/styles/variables';
@import './components/app/utils/styles/mixins';

@font-face {
    font-family: ModernEraBlack;
    src: url('/assets/fonts/ModernEra/ModernEra-Black.otf');
}

@font-face {
    font-family: ModernEraBlackItalic;
    src: url('/assets/fonts/ModernEra/ModernEra-BlackItalic.otf');
}

@font-face {
    font-family: ModernEraBold;
    src: url('/assets/fonts/ModernEra/ModernEra-Bold.otf');
}

@font-face {
    font-family: ModernEraBoldItalic;
    src: url('/assets/fonts/ModernEra/ModernEra-BoldItalic.otf');
}

@font-face {
    font-family: ModernEraExtraBold;
    src: url('/assets/fonts/ModernEra/ModernEra-ExtraBold.otf');
}

@font-face {
    font-family: ModernEraExtraBoldItalic;
    src: url('/assets/fonts/ModernEra/ModernEra-ExtraBoldItalic.otf');
}

@font-face {
    font-family: ModernEraItalic;
    src: url('/assets/fonts/ModernEra/ModernEra-Italic.otf');
}

@font-face {
    font-family: ModernEraLight;
    src: url('/assets/fonts/ModernEra/ModernEra-Light.otf');
}

@font-face {
    font-family: ModernEraLightItalic;
    src: url('/assets/fonts/ModernEra/ModernEra-LightItalic.otf');
}

@font-face {
    font-family: ModernEraMedium;
    src: url('/assets/fonts/ModernEra/ModernEra-Medium.otf');
}

@font-face {
    font-family: ModernEraMediumItalic;
    src: url('/assets/fonts/ModernEra/ModernEra-MediumItalic.otf');
}

@font-face {
    font-family: ModernEraRegular;
    src: url('/assets/fonts/ModernEra/ModernEra-Regular.otf');
}

#root {
    height: 100vh;
    background: #f5f6fc;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/** GLOBAL STYLES **/

/** GENERAL ---------------------------- **/

h1 {
    font-size: 2em;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: $mobile-width) {
        font-size: 1.7rem;
    }
}

h2 {
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

h3 {
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
}

h4 {
    font-size: 0.9em;
    font-weight: bold;
    width: 100%;
    color: #777;
    display: block;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 5px;
    border-bottom: 1px solid #999;
}

p {
    font-size: 0.8em;
    font-family: 'Open Sans', sans-serif;
}

*:focus {
    outline: none;
}

/** MARGIN / SPACING ---------------------------- **/

.general-padding {
    padding: $general-padding;
}

/** TEXT STYLES ---------------------------- **/

.text-subtle {
    font-weight: 400;
    color: #999;
}

.text-alert {
    font-weight: 400;
    color: #ff5252;
}

.text-success {
    font-weight: 400;
    color: #08d816;
}

.text-small {
    font-size: 0.8em;
}

/** BUTTONS ---------------------------- **/

button,
a.btn {
    img {
        vertical-align: middle;
        margin-right: 5px;
    }

    span {
        vertical-align: middle;
    }
}

/** FORM ELEMENTS ---------------------------- **/

label {
    font-weight: bold;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
}

textarea,
select {
    font-size: 0.8em;
}

input[type='text'],
input[type='tel'],
input[type='email'] {
    margin-bottom: 10px;
    font-size: 0.8em;
}

.input-set {
    margin-bottom: 20px;
}

.radio-set {
    input[type='text'] {
        margin: 0;
        vertical-align: middle;
    }

    span {
        margin-right: 10px;
        display: inline-block;
    }

    span > * {
        display: inline-block;
    }

    label {
        margin-bottom: 0;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 28px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #30cb4a;
}

input:focus + .slider {
    box-shadow: 0 0 1px #30cb4a;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.rich-text-editor {
    min-height: 250px;
}

#tuiContainer {
    z-index: 999999;
}

.input {
    &--color {
        height: 32px;
        width: 30px;
        border-top: 1px solid #dadeeb;
        border-bottom: 1px solid #dadeeb;
        border-left: 1px solid #dadeeb;
        border-right: 0;
        background: #fff;
        border-radius: 4px 0 0 4px;

        &_content {
            border-left: 0 !important;
            background: #fff !important;
            border-radius: 0 4px 4px 0;
            padding-left: 5px;
        }
    }
}

// Toastr Image Editor
.tui-image-editor-header-logo:after {
    content: 'Image Editor';
    color: #ffffff;
}

.tui-image-editor-header-logo img {
    display: none;
}

/** BOOTSTRAP OVERRIDES ---------------------------- **/

.btn {
    font-weight: 600;
    padding: 5px 15px;
    font-family: 'Open Sans', sans-serif;

    &-secondary {
        background: none;
        color: #333;
        border: none;

        &:hover {
            background: #eee;
            color: #333;
        }
    }
}

.modal-content {
    border-radius: 10px;
}

.modal-body {
    // padding: 30px;
    padding: 0;
}

.form-group {
    textarea,
    select {
        font-size: 0.8em;
    }
}

.form-control {
    border: 1px solid #dadeeb;

    textarea {
        font-size: 0.8em;
    }
}

.input-group {
    width: unset;
}

.input-group-text {
    font-size: inherit;
    color: #9da2b5;
    border: 1px solid #dadeeb;
    border-right: 0;
    background: #f3f5fa;
}

.input-group-append {
    span {
        color: #333;
        font-weight: bold;

        &:hover {
            background: #236df2;
            color: #fff;
        }
    }
}

/** MODAL ---------------------------- **/

.custom-modal {
    &--large {
        max-width: 1000px;
    }

    &--medium {
        max-width: 700px;
    }

    &--small {
        max-width: 500px;
    }
}

.modal-open {
    overflow: hidden !important;
}

/** TABLE ---------------------------- **/

.table-general {
    width: 100%;

    thead {
        border-bottom: 1px solid #d5ddf1;

        th {
            font-size: 0.8em;
            padding: 10px;
            min-width: 100px;

            &:first-child {
                padding-left: 20px;
            }

            &.verticalAlignMiddle {
                vertical-align: middle !important;
            }
        }
    }

    tbody {
        tr {
            &:hover {
                background: #f7f7f7;
            }
        }
    }

    tr {
        border-bottom: 1px solid #d5ddf1;

        &:last-child {
            border: none;
        }
    }

    td {
        padding: 10px;
        font-size: 0.9em;
        position: relative;
        font-family: 'Open Sans', sans-serif;

        &:first-child {
            width: 200px;
            padding-left: 20px;
        }

        &:last-child {
            text-align: right;
            padding-right: 20px;
        }

        p {
            margin-bottom: 0;
            font-size: 0.9rem;
        }

        &.verticalAlignMiddle {
            vertical-align: middle !important;
        }
    }
}

/** MISC ---------------------------- **/

.header-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    align-content: center;

    > * {
        margin-bottom: 0;
    }
}

.no-margin {
    margin: 0;
}

.loading {
    padding: 20px;
    text-align: center;
}

.grouper {
    display: flex;

    @media screen and (max-width: $mobile-width) {
        display: block;
    }

    &--right {
        justify-content: flex-end;

        > * {
            margin-right: 10px;

            @media screen and (max-width: $mobile-width) {
                margin-right: 0;
                margin-bottom: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.arrow-back {
    cursor: pointer;
    width: 15px;
    margin-right: 8px;
}

.icon-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.text-error {
    color: #dc3545 !important;
    font-weight: bold;
}

.text-rich {
    &__wrap {
        div[role='textbox'] {
            font-family: 'Roboto', sans-serif !important;
            font-size: 0.9em;
        }
    }
}

.panel-empty {
    text-align: center;
    color: #b0bacf;
    padding: 70px 0;

    h2 {
        font-size: 1.5em;
    }

    p {
        font-size: 1em;
        margin-bottom: 0;
    }
}

.error-page {
    @include centerer;
    width: 100%;

    &__content {
        text-align: center;
    }
}

.page-title {
    @include space-between;

    * {
        margin-bottom: 0;
    }
}

.set-wrap {
    @media screen and (max-width: $mobile-width) {
        margin-bottom: 30px;
    }
}

input[type='color'] {
    -webkit-appearance: none;

    &::-webkit-color-swatch {
        border-radius: 2px;
        border: 1px solid #dadeeb;
    }
}

// //Need this to display popups due to Froala bug that sets popup z-index to 4
// .fr-popup.fr-desktop.fr-active {
//     z-index: 2147483644 !important;
// }

#tuiContainer {
    z-index: 9999999999;
}

.verticalAlignMiddle {
    vertical-align: middle !important;
}

#fc_frame {
    z-index: 998 !important;
}
