.session-container {
    margin-top: 22px;

    .addSession {
        height: 48px;
        width: 100%;
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        letter-spacing: -0.3px;

        img {
            margin-right: 5px;
            width: 14px;
            height: 14px;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.8;
            border: 1px solid rgba(128, 128, 128, 0.37);
        }
    }

    .no-record {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        @media screen and (max-width:730px) {
            padding-top: 75px;
        }


        h2 {
            color: #b7c3cf;
            font-size: 26px !important;
            letter-spacing: -0.7px !important;
            line-height: 32px !important;
            margin-bottom: 0;

            @media screen and (max-width:730px) {
                font-size: 20px !important;
            }
        }

        p {
            color: #b7c3cf;
            font-size: 15px !important;
            letter-spacing: -0.4px !important;
            line-height: 20px !important;
        }
    }

    .session-lists {
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        position: relative;

        @media screen and (max-width:640px) {
            font-family: 'Open Sans';
        }

        .session-timezone {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 180px;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .session-dates {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            padding-bottom: 5px;
            overflow-y: auto;
            width: 100%;
            max-width: calc(100% - 180px);

            &::-webkit-scrollbar {
                width: 8px;
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #c4c9d1;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #c4c9d1;
            }

            span {
                color: #b4bfd2;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.3px;
                font-family: 'Montserrat Bold', sans-serif;
                font-weight: bold;
                margin-right: 5px;
                min-width: 115px;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }

                &.active {
                    color: #202123;
                }
            }
        }

        .session {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            padding-bottom: 5px;
            overflow-y: auto;
            width: 100%;
            position: relative;
            min-height: 200px;

            .session-item {
                display: flex;
                flex-direction: row;
                padding: 16px 16px 22px;
                background: #ffffff;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                margin: 5px 0;

                @media screen and (max-width:640px) {
                    padding: 16px 0px 22px;
                }

                .left {
                    text-align: center;

                    @media screen and (max-width:640px) {
                        text-align: start;
                    }

                    span {
                        color: #202123;
                        font-size: 14px;
                        letter-spacing: -0.2px;
                        font-weight: bold;
                    }

                    .extraday-text {
                        font-size: 10px;
                        vertical-align: top;
                        color: #ff0000;
                        padding-left: 3px;
                    }

                    .hidden {
                        visibility: hidden;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .header {
                        border: 0;
                        padding: 0;
                        z-index: 1;

                        h2 {
                            color: #202123;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: -0.3px;
                            line-height: 22px;

                            @media screen and (max-width:640px) {
                                font-family: 'Open Sans';
                            }
                        }

                        .actions {
                            span {
                                color: #939ea5;
                                font-size: 12px;
                                letter-spacing: -0.2px;
                                cursor: pointer;

                                &:hover {
                                    color: #236df2;
                                }

                                &:first-child {
                                    margin-right: 20px;

                                    @media screen and (max-width:640px) {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }

                    .tags {
                        display: flex;
                        margin: 0 0 5px;
                        flex-wrap: wrap;

                        span {
                            height: 22px;
                            font-size: 10x;
                            line-height: 14px;
                            padding: 4px 7px;
                            color: #0b1c2c;
                            background: #e2e9f3;
                            margin-right: 5px;
                            border-radius: 3px;
                            font-weight: bold;
                            margin-bottom: 5px;
                        }
                    }

                    .description {
                        display: flex;
                        margin: 5px 0 17px;
                        flex-direction: column;

                        @media screen and (max-width:640px) {
                            margin: 0;
                        }

                        p,
                        span,
                        div {
                            color: #2d2e31 !important;
                            background: transparent !important;

                            @media screen and (max-width:640px) {
                                font-size: 14px;
                            }
                        }
                    }

                    .speakers-container {
                        h4 {
                            color: #202123;
                            font-family: 'Open Sans SemiBold', sans-serif;
                            font-size: 12px;
                            line-height: 17px;
                            margin-bottom: 0;

                            @media screen and (max-width:640px) {
                                font-size: 16px;

                            }
                        }

                        .speakers {
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;

                            .speaker {
                                display: flex;
                                flex-direction: row;
                                margin-bottom: 5px;
                                padding: 3px;
                                border-radius: 4px;
                                text-transform: uppercase;

                                &:hover {
                                    cursor: pointer;
                                    background: #d2ddee;
                                }

                                img.profile {
                                    width: 28px;
                                    height: 28px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }

                                .speaker_profile {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: #e4eaf7;
                                    width: 28px;
                                    height: 28px;
                                    font-family: 'Montserrat', sans-serif;
                                    font-weight: 800;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }

                                .speaker-details {
                                    flex-direction: column;
                                    margin-right: 24px;

                                    h5 {
                                        font-family: 'Montserrat SemiBold',
                                            sans-serif;
                                        color: #202123;
                                        line-height: 16px;
                                        letter-spacing: -0.2px;
                                        font-size: 13px;
                                        margin: 0;

                                        @media screen and (max-width:640px) {
                                            font-family: 'Open Sans';
                                            font-size: 14px;
                                            font-weight: 600;
                                        }

                                    }

                                    span {
                                        color: #939ea5;
                                        font-family: 'Open Sans SemiBold',
                                            sans-serif;
                                        font-size: 11px;
                                        line-height: 11px;
                                        letter-spacing: -0.2px;

                                        @media screen and (max-width:640px) {
                                            font-family: 'Open Sans';
                                            font-size: 14px;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #spinnerContainer {
                position: absolute;
                text-align: center;
                margin: 0 auto;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 200px;
            }
        }
    }
}