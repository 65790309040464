.otp-box {
    input {
        margin-right: 10px !important;
        &:last-child {
            margin-right: 0 !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .Twofa div[data-testid='otp-input-root'] input[type='tel'] {
        width: 35px !important;
        height: 30px !important;
        border-width: 1px !important;
        // borderRadius: '0.375rem',
        font-size: 0.85rem !important;
        line-height: 1.25rem !important;
        font-weight: 800 !important;
        margin-right: 10px !important;
    }
}
