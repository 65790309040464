@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.submenu-top {
    position: relative;
    left: 18px;
    transition: 0.4s;
    width: fit-content;
    cursor: pointer;
    margin-left: 0px;
    transition: margin-left 0.55s;

    & .myxp-arrow-back {
        width: 20px;
        height: 15px;
        cursor: pointer;
        position: relative;
        left: 3px;
        -webkit-transition: 0.2s ease-out;
        -moz-transition: 0.2s ease-out;
        -ms-transition: 0.2s ease-out;
        -o-transition: 0.2s ease-out;
        transition: 0.2s ease-out;

        &:hover {
            left: -2px !important;
            -webkit-transition: 0.2s ease-in;
            -moz-transition: 0.2s ease-in;
            -ms-transition: 0.2s ease-in;
            -o-transition: 0.2s ease-in;
            transition: 0.2s ease-in;
        }
        &::before {
            cursor: pointer;
            position: absolute;
            content: '';
            border: 2px solid black;
            width: 10px;
            height: 10px;
            top: 2px;
            left: 0;
            border-top: 0;
            border-left: 0;
            transform: rotate(135deg);
        }
        &::after {
            cursor: pointer;
            content: '';
            border-top: 2px solid black;
            width: 11px;
            position: absolute;
            top: 6px;
            border-radius: 5px;
        }

        & + h2 {
            margin: 0 0 0 5px;
            font-size: 16px !important;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: -0.66px;
            color: #000;
            word-break: break-word;
            width: 7rem;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            white-space: initial;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    & .submenu-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 1.1rem;
        padding-left: 38px;
        width: 12rem;
        text-overflow: ellipsis;
        white-space: initial;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 0;

        &::before {
            position: absolute;
            top: 3px;
            left: 8px;
            content: '';
            height: 40px;
            width: 40px;
            background-size: cover;
            background: url(/assets/icon_back_arrow.svg) no-repeat;
            transition: 0.4s;
        }

        &:hover {
            cursor: pointer;
        }

        &:hover::before {
            transition: 0.4s;
            cursor: pointer;
            left: 3px !important;
        }
    }

    // For LiveSite pages menu
    & .livesite-pages-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .livesite-pages-title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 18px !important;
            line-height: 22px;
            letter-spacing: -0.74px;
            color: #000;
        }

        & label {
            position: relative;
            top: -3px;
        }

        & input:checked + .slider {
            background-color: #236df2;
        }
    }

    & .dropdown .dropdown-menu {
        border-radius: 0 0 5px 5px;
        margin-top: -1px;
        z-index: 5;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 5px 10px;
        font-size: 0.8rem;
        font-family: 'Open Sans Regular', sans-serif;
        font-weight: bold;

        &:hover {
            cursor: pointer;
        }
    }

    & .dropdown .btn,
    & .dropdown .dropdown-menu .dropdown-item {
        background: #c5dcff;
        width: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        padding: 5px 10px;
        z-index: 10;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.53px;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        color: #000;
        margin: 10px 0;

        &:disabled,
        &[disabled] {
            background: #e7ebf0 !important;
            color: inherit !important;
        }

        &:focus {
            box-shadow: unset !important;
        }

        & svg {
            margin-top: 0;
        }
    }
    & .dropdown .btn.live {
        background: #35b157;
        color: white;
    }

    & .btn.prev {
        background: #d2dceb;
        width: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        padding: 5px 10px;
        z-index: 10;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.53px;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        color: #000;
        margin: 10px 0;

        &:disabled,
        &[disabled] {
            background: #e7ebf0 !important;
            color: inherit !important;
        }

        &:focus {
            box-shadow: unset !important;
        }
    }

    &.submenuclicked {
        margin-left: -1000px;
        transition: margin-left 2.1s;
    }
}

// The SubMenu's nav ul
ul.app-submenu {
    margin-left: 20px;
    transition: margin-left 0.55s;

    &.submenuclicked {
        margin-left: -1000px;
        transition: margin-left 2.1s;
    }

    & li.nav-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 0;

        &__sub {
            height: 23px;
            width: 100%;
            margin: 6px 0;

            &.hidden {
                display: none;
            }

            & a#sub {
                padding: 0;
            }
        }
    }

    & .nav-item.active {
        // For the active link marker
        & .marker {
            width: 5px;
            height: 41px;
            background: #191919;
            position: fixed;
            left: 0;
            display: block;
            margin-top: 2px;
            border-radius: 6px;

            &.x {
                margin-top: -1px !important;
            }
        }
    }

    // All styles for <p></p>
    & .nav-item p {
        width: 0;
        padding-left: 42px;

        // Styles for large font links
        &.main {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1.04rem;
        }
        // Styles for small font links
        &.sub {
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 0.85rem;
            margin-left: -18px;
            margin-bottom: 0px;
            white-space: nowrap;
        }

        // Custom style for each nav link, inside <p></p>
        // cause we need to add icon
        // Settings Link
        &.sidebar-settings {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_settings_normal.svg)
                    no-repeat;
            }
        }
        // LiveWidgets Link
        &.sidebar-livewidgets {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_livewidgets_normal.svg)
                    no-repeat;
            }
        }
        // Content Link
        &.sidebar-content {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_content_normal.svg)
                    no-repeat;
            }
        }
        // Content Link for LiveSite pages
        &.sidebar-content.livesite-pages {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_content_normal.svg)
                    no-repeat;
            }
        }
        &.sidebar-companyprofile {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_content_normal.svg)
                    no-repeat;
            }
        }
        &.sidebar-agenda {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/switch/submenu/icon-agenda.svg)
                    no-repeat;
            }
        }
        &.sidebar-polls {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/switch/submenu/icon-polls.svg) no-repeat;
            }
        }
    }

    // All styles for specific nav link that is active and hovered
    & a {
        width: 0px;
        color: black;

        &.disabled {
            color: #73767a !important;
        }

        // If menu is not clicked, return to default colors both .main and .sub <p></p>
        //&, &.active .sub {
        //  -webkit-transition: color 1.2s;
        //  -moz-transition: color 1.2s;
        //  -o-transition: color 1.2s;
        //  transition: color 1.2s;
        //}
        //
        //// If menu bar is collpsed hide link texts by changing colors
        //&.submenuclicked, &.active.submenuclicked {
        //  color: transparent;
        //  -webkit-transition: color .9s;
        //  -moz-transition: color .9s;
        //  -o-transition: color .9s;
        //  transition: color .9s;
        //  margin-left:-1000px;
        //  transition: margin-left 2s;
        //}

        // Active or Hovered: Settings
        & .sidebar-settings:hover,
        &.active .sidebar-settings {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_settings_colored.svg)
                    no-repeat;
            }
        }
        // Active or Hovered: LiveWidgets
        & .sidebar-livewidgets:hover,
        &.active .sidebar-livewidgets {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_livewidgets_colored.svg)
                    no-repeat;
            }
        }
        // Active or Hovered: Content
        & .sidebar-content:hover,
        &.active .sidebar-content {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_content_colored.svg)
                    no-repeat;
            }
        }
        // Active or Hovered: Content for LiveSite pages
        & .sidebar-content.livesite-pages:hover,
        &.active .sidebar-content.livesite-pages {
            color: #236df2;

            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_content_colored.svg)
                    no-repeat;
            }
        }

        & .sidebar-agenda:hover,
        &.active .sidebar-agenda {
            color: #236df2;
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                border-radius: 2px;
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/switch/submenu/icon-agenda-active.svg)
                    no-repeat;
            }
        }
        & .sidebar-polls:hover,
        &.active .sidebar-polls {
            color: #236df2;
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                border-radius: 2px;
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/switch/submenu/icon-polls-active.svg)
                    no-repeat;
            }
        }

        // Disabled links: Settings, LiveSite Pages
        &.disabled .sidebar-settings {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_settings_disabled.svg)
                    no-repeat;
            }
        }
        // Disabled links: Content, LiveSite pages
        &.disabled .sidebar-content.livesite-pages {
            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                content: '';
                height: 40px;
                width: 40px;
                background-size: cover;
                background: url(/assets/products/xpmanager/icons/submenu/icon_content_disabled.svg)
                    no-repeat;
            }
        }
    }
}

// The hamburger menu for subMenu
#subMenu {
    position: relative;
    display: flex;
    width: 100%;
    padding: 18px 10px 10px;
    width: 0px;
    left: 150px;
    transition: left 0.6s;

    &:hover > .span1::before {
        box-shadow: 0 -4px 0 0 #000000, 0 -8px 0 0 #000000 !important;
    }
    &:hover > .span1 {
        background: #000000 !important;
    }

    & .span1 {
        width: 9px;
        height: 2px;
        background: #bdc9dc;
        border-radius: 4px;

        &::before {
            display: block;
            content: '';
            width: 12px;
            height: 2px;
            box-shadow: 0 -4px 0 0 #bdc9dc, 0 -8px 0 0 #bdc9dc;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.submenuclicked {
        left: 0px !important;
        transition: left 0.6s;
    }
}
