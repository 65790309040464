@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerer-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

@mixin centerer-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin list-horizontal {
  @include list-reset;
  display: flex;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin space-between-vertical {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@mixin panel-regular($padding: 30px) {
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(46, 91, 255, 0.15);
  padding: $padding;
}
