.tooltip-fullname {
    .ant-tooltip {
        max-width: 250px;
        .ant-tooltip-inner {
            text-align: center;
            word-break: break-word;
        }
        .ant-tooltip-arrow {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 15px solid rgba(0, 0, 0, 0.65);
            .ant-tooltip-arrow-content {
                display: none;
            }
        }
    }
}
