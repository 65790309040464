.project .gallery{
  display:flex;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;

  & .imageloader {
    width: 90px;
    height: 53px;
    margin: 5px;
    cursor: pointer;

    &.active {
      border: 3px solid #2D76FA;
    }

    img{
      width: 90px;
      height: 53px;
      cursor: pointer;
      animation: zoomIn 700ms;
      -webkit-transition: ease-in-out 700ms;
      -moz-transition: ease-in-out 700ms;
      -ms-transition: ease-in-out 700ms;
      -o-transition: ease-in-out 700ms;
      transition: ease-in-out 700ms;
    }
  }
}
