@import "../../utils/styles/mixins";

.option-tooltip {
  background-color: #333333;
  border-radius: 7px;
  padding: 15px;
  width: 200px;
  color: #fff;
  position: absolute;

  p {
    margin-bottom: 7px;
  }

  &__list {
    @include list-reset;

    font-weight: bold;
    width: 100%;
    cursor: pointer;

    li {
      background: url("/assets/icon_tile_small.svg") no-repeat 7px center;
      padding: 4px 4px 4px 30px;
      border-radius: 3px;

      &:hover {
        background-color: #444;
        
      }
    }
  }

  &-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &-enter-active {
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
}
