.VenueBuilder {
    display: flex;
    height: 100%;
    overflow: hidden;
    font-size: 14px;
}

.AppHeader {
    height: auto;
    background: #fff;
    padding: 0;
    line-height: normal;
    box-shadow: 0px 2px 4px rgba(178, 199, 238, 0.3);
}

.AppSideBar {
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    width: 300px;
    box-shadow: 0px 2px 100px rgba(178, 199, 238, 0.25);
}

.AppSideBar::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.AppSideBar::-webkit-scrollbar-thumb {
    background: none;
}

.AppContent {
    display: flex;
    overflow: hidden;

    padding: 20px;
    background-color: #eef1f7;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.myxp {
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans', sans-serif !important;
        font-weight: 600;
        font-size: 13px !important;
        line-height: 18px !important;
        letter-spacing: -0.53px !important;
    }
}

.form-input-container {
    input {
        padding: 8px 11px;
    }

    input:is([type='color']) {
        padding: 0px;
    }
}

.cta-color-input {
    height: calc(1.5em + 0.75rem + 3px) !important;
}

.notification-input-label {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    letter-spacing: -0.02em !important;
    color: #000000 !important;
}

.panel-header-collapse {
    .ant-collapse-header {
        padding: 0px !important;
    }
}

.position-radio-button {
    border: inherit;

    &:not(:first-child)::before {
        display: none;
    }

    &:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none !important;
    }
}

.Heading h3 {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 13px !important;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.Heading h3 span {
    margin-left: 8px;
}

.ContentContainer {
    border: 1px solid #dadeeb;
    box-sizing: border-box;
    border-radius: 4px;
}

.AddImage {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dotted #000000;
    margin: 10px 16px;
    padding: 7px;
    text-align: center;
    border-radius: 4px;
}

.AddImage span {
    padding-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.AddImage:hover {
    background: #f6f6f7;
    cursor: pointer;
}

.ImageLists {
    display: flex;
    flex-direction: column;
}

.ImageList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e3e8f7;
}
