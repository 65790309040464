.panel-CTA-content {
    .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-header {
        padding: 0 !important;
    }
}

.video-preview-container {
    border: 1px solid #DADEEB;
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 170px;
    color: #A1A1A1
}

.cta-color-input{
    height: 32px!important;
}