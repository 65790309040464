.ChatData {
    position: relative;
    max-width: 1140px;
    margin: auto;
}

.chat-data-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.chat-data-table {
    thead {
        tr {
            th {
                padding-top: 22px;
                padding-bottom: 22px;
                cursor: auto;

                &:nth-child(1) {
                    max-width: 281px;
                    width: 281px;
                    padding-left: 10px;
                }
                &:nth-child(2) {
                    max-width: 80px;
                    width: 80px;
                    padding-left: 5px !important;
                }
                &:nth-child(3) {
                    max-width: 250px;
                    width: 250px;
                    margin: 0 0 0 auto;
                }
            }
        }
    }
    tbody tr td {
        padding-top: 22px !important;
        padding-bottom: 22px !important;
        vertical-align: baseline !important;
    }
    tr {
        border-bottom: 1px solid #d7dfe8 !important;
        &.checked {
            background: #f2f5fa;
        }
        td {
            &:nth-child(1) {
                max-width: 281px;
                width: 281px;
                margin-top: 22px !important;
                padding-left: 10px;
            }
            &:nth-child(2) {
                margin-top: 22px !important;
                max-width: 80px;
                width: 80px;
                font-family: 'Montserrat SemiBold', sans-serif;
                font-size: 15px;
                text-overflow: inherit;
                overflow: visible;
                white-space: initial;
                padding-left: 5px !important;
                a {
                    color: #000 !important;
                    font-weight: 600;
                    flex-wrap: wrap;
                    display: flex;
                    padding-right: 15px;
                }
            }
            &:nth-child(3) {
                max-width: 250px;
                width: 250px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                margin: 0 0 0 auto;

                button.btn-export {
                    font-family: 'Open Sans SemiBold', sans-serif !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    text-align: left;
                    font-size: 14px;
                    background: #d2dceb;
                    img {
                        width: 12px;
                        margin-left: 18px;
                        margin-right: 0px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.chat-livepage-search {
    position: absolute;
    top: -32px;
    right: 0px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-group {
        width: fit-content;
        margin: 0px 12px 0px auto;
    }
}

.chat-table-paginate {
    max-width: 1140px;
    padding-left: 0.75rem;
}
